import React, { useEffect, type FC } from 'react'
import { t } from 'i18next';

import { ButtonComponent, ProgressComponent, useSnackbar } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { fetchUserVerificationData, getUserVerificationProgress } from '../../../../redux/reducers/kycVerification';
import { selectIsUserDataAvailable } from '../../../../redux/selectors/user';
import { getKycTypes } from '../../../../redux/reducers/kycTypes';
import { selectKycTypesError, selectKycTypesLoading } from '../../../../redux/selectors/kycTypes';
import { CompleteProgress } from './CompleteProgress/CompleteProgress';
import { DocsList } from './DocsList/DocsList';
import { kycVerificationDocumentUserProgress } from '../../../../redux/selectors/kycVerification';

import style from './CompleteProfileSettings.module.css';

interface InterfaceCompleteProfileSettingsProps {
  setOpenVerificationModal: (el: boolean) => void
}

export const CompleteProfileSettings: FC<InterfaceCompleteProfileSettingsProps> = ({ setOpenVerificationModal }) => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar();
  const { totalKycDocsAmount = 0, verifiedPercentage = 0 } = useAppSelector(kycVerificationDocumentUserProgress);

  const kycTypesLoading = useAppSelector(selectKycTypesLoading);
  const kycTypesError = useAppSelector(selectKycTypesError);

  const isUserDataAvailable = useAppSelector(selectIsUserDataAvailable);

  useEffect(() => {
    dispatch(getUserVerificationProgress());
  }, []);

  useEffect(() => {
    if (isUserDataAvailable) {
      dispatch(fetchUserVerificationData())
      dispatch(getKycTypes());
    }
  }, [isUserDataAvailable])

  useEffect(() => {
    if (kycTypesError) {
      handleOpen({
        message: 'Kyc/Aml Types Error',
        actionText: '',
        severity: 'error'
      });
    }
  }, [kycTypesError, handleOpen]);

  const completeKyc = verifiedPercentage === 100;

  if (kycTypesLoading) {
    return <ProgressComponent type="circular" />;
  }

  return (
    <div className={style.identityVerificationContentContainer}>
      <div className={style.identityVerificationProgreesContainer}>
        <div className={style.identityVerificationProgreesContent}>
          <p className={style.identityVerificationProgreesText}>{t('labels.verification_progress_files_uploaded_and_approved', { totalKycDocsAmount })}</p>
          <div className={style.identityVerificationBtnContainer}>
            {
              !completeKyc
                ? (
                    <ButtonComponent
                      variant='text'
                      onClick={() => {
                        setOpenVerificationModal(true);
                      }}
                    >
                      {t('profile_page.settings.complete_profile.identity_verifications.verify_button_text')}
                    </ButtonComponent>
                  )
                : (
                    <div className={style.identityVerificationCompleteLabel}>{t('transaction_statuses.Completed')}</div>
                  )
            }
          </div>
        </div>
        <CompleteProgress completedPercent={ verifiedPercentage ?? 0 }/>
      </div>
      <div className={style.docsProgreesListContainer}>
        <DocsList />
      </div>
    </div>
  )
}
