import React, { type InputHTMLAttributes, type FC, type ChangeEvent, type ReactNode, useEffect, useState } from 'react'
import { InputAdornment, TextField, styled, type TextFieldProps } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { getAccountIconBySymbol } from '../../helpers/util';

import style from './AssetAmountInput.module.css';

interface AssetAmountInputInterface {
  id?: string
  value: string
  setValue: (el: string) => void
  placeholder?: string
  error?: boolean
  disabled?: boolean
  helperTextStatic?: string
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  icon?: ReactNode
  currency?: string
  maxBtn?: boolean
  maxValue?: string
  showCurrency?: boolean
}

type TInput = AssetAmountInputInterface & InputHTMLAttributes<HTMLInputElement> & TextFieldProps;

const CustomizedInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    height: '40px',
    paddingLeft: '0',
    borderRadius: '4px',
    border: '1px solid var(--CFD-theme-System-OnSurfaceVariant)',
    '&.Mui-error': {
      border: '3px solid var(--CFD-theme-System-Error) !important'
    },
    '&.Mui-focused': {
      border: '3px solid var(--CFD-theme-System-Primary)',
      '&.Mui-error': {
        border: '3px solid var(--CFD-theme-System-Error) !important'
      }
    },
    '& .MuiSvgIcon-root': {
      color: 'var(--CFD-theme-System-OnSurfaceVariant)'
    }
  },
  '& .MuiInputBase-root.Mui-error .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-Error) !important'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    fontFamily: 'Arial',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',

    '&.Mui-disabled ': {
      WebkitTextFillColor: 'unset'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

const paddingLeftObj: { 3: number, 4: number, 5: number, 6: number } = {
  3: 50,
  4: 60,
  5: 65,
  6: 75
}

export const AssetAmountInputComponent: FC<TInput> = ({
  id,
  value,
  setValue,
  placeholder,
  error,
  disabled,
  helperTextStatic,
  required,
  variant = 'outlined',
  icon,
  currency = '',
  maxBtn = true,
  maxValue = '0',
  showCurrency = true
}) => {
  const [paddingLeft, setPaddingLeft] = useState<number>(50);

  const handleClear = (): void => {
    if (setValue != null) setValue('');
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = event;
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value) || value === '') {
      setValue(value);
    }
  };

  const handleMaxBtn = (): void => {
    if (setValue != null && (maxValue.length > 0)) setValue(maxValue);
  }

  useEffect(() => {
    const paddingSize = currency.length > 0 ? (paddingLeftObj[currency.length as keyof typeof paddingLeftObj] ?? 75) : 0;
    setPaddingLeft(paddingSize);
  }, [currency])

  const cancelOutlinedIconSX = {
    cursor: 'pointer',
    marginRight: ((icon !== undefined && currency !== '') ? '15px' : '0'),
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  }

  return (
    <div className={ style.inputContainer }>
      <CustomizedInput
        id={ id }
        value={ value }
        onChange={ handleChange}
        placeholder={ placeholder }
        error={ error }
        disabled={ disabled }
        size='medium'
        variant={ variant }
        required={ required }
        helperText= { helperTextStatic }
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            paddingLeft: `${paddingLeft}px`,
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <>
                { (value.length > 0 && (disabled === undefined || !disabled)) && <CancelOutlinedIcon onClick={handleClear} sx={cancelOutlinedIconSX} /> }
                { (currency.length > 0 && showCurrency) && <span className={ style.iconsContainer }>
                  <img src={ getAccountIconBySymbol(currency) } />
                  { currency }
                </span>}
              </>
            </InputAdornment>
          ),
          startAdornment: (
          <InputAdornment position="start">
            { maxBtn
              ? <button className={ style.inputMaxButton } onClick={ handleMaxBtn }>Max</button>
              : <p className={ style.currencyTitle }>{currency}</p> }
          </InputAdornment>)
        }}
      />
    </div>
  );
};
