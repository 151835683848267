import React, { type FC } from 'react'
import { t } from 'i18next';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

import { ProgressComponent } from '../../../../../components';

import style from './CompleteProgress.module.css'

interface ICompleteProgress {
  completedPercent: number
}

export const CompleteProgress: FC<ICompleteProgress> = ({ completedPercent = 0 }) => {
  return (
    <div className={style.profileCompiled}>
      <div className={style.completeProgress}>
        <div className={style.progressBarLine}>
          <ProgressComponent type='linear' variant="determinate" value={completedPercent} sx={{ width: '100%' }} />
        </div>
        <div className={style.verifyIconBlock}>
          <div className={style.verifyIconWrapper}>
            <VerifiedUserOutlinedIcon sx={{ width: '12px', height: '12px' }} />
          </div>
        </div>
      </div>
    </div>
  )
}
