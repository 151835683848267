export enum ProfileElementHeight {
  PaddingTop = 16,
  Footer = 64,
  Header = 52,
  Gap = 24,
  BalanceChartSection = 361,
  StatisticSection = 276,
  TradingChartSection = 403,
  Border = 2,
  TableStatisticSection = 556
}
