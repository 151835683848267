import React, { type FC } from 'react';

import { getAccountIconBySymbol } from '../../../helpers/util';
import { type OptionInterface } from '../../../interfaces';

import styles from '../SelectComponent.module.css';

interface ISelectComponentValueView {
  selectedOption: OptionInterface
  languageOptions: boolean
}

export const SelectComponentValueView: FC<ISelectComponentValueView> = ({ selectedOption, languageOptions = false }) => {
  const imageSrcType = languageOptions ? (selectedOption.symbol ?? '') : getAccountIconBySymbol((selectedOption.symbol as string) ?? '');
  const imageBorderRadius = languageOptions ? '100px' : '0';
  return (
    <div className={styles.currencyContainer}>
      {selectedOption.symbol?.length > 0 && (
        <img
          src={imageSrcType}
          className={styles.currencyIcon}
          style={{ borderRadius: imageBorderRadius }}
        />
      )}
      <p className={styles.currencyTitle}>{(selectedOption.label as string) ?? ''}</p>
    </div>
  )
}
