import React, { type FC, useMemo } from 'react';
import { t } from 'i18next';

import { type VerificationData } from '../../../../../../redux/reducers/kycVerification';

import style from './DocsListLabel.module.css';

interface IDocsListLabel {
  documentData: VerificationData
}

export const DocsListLabel: FC<IDocsListLabel> = ({ documentData }) => {
  const labelData = useMemo(() => ({
    verified: {
      color: 'var(--CFD-theme-System-Tertiary)',
      background: 'var(--CFD-theme-State-layers-tertiary-opacity_08)',
      title: t('labels.approved')
    },
    'not verified': {
      color: 'var(--CFD-theme-System-Error)',
      background: 'var(--CFD-theme-State-layers-error-opacity_08)',
      title: t('labels.declined')
    },
    'partially verified': {
      color: 'var(--CFD-theme-System-Secondary)',
      background: 'var(--CFD-theme-State-layers-secondary-opacity_08)',
      title: t('labels.pending')
    },
    declined: {
      color: 'var(--CFD-theme-System-Error)',
      background: 'var(--CFD-theme-State-layers-error-opacity_08)',
      title: t('labels.declined')
    },
    undefined: {
      color: 'var(--CFD-theme-System-Outline)',
      background: 'var(--CFD-theme-State-layers-outline-opacity_08',
      title: t('labels.no_uploaded')
    },
    pending: {
      color: 'var(--CFD-theme-System-Secondary)',
      background: 'var(--CFD-theme-State-layers-secondary-opacity_08)',
      title: t('labels.pending')
    }
  }), [t]);

  const labelView = labelData[documentData?.verifiedStatus as keyof typeof labelData];

  return (
    <div
      className={style.docsProgreesListLabel}
      style={{ backgroundColor: labelView.background, color: labelView.color }}
    >
      {labelView.title}
    </div>
  )
}
