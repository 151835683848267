import React from 'react';
import { styled } from '@mui/material/styles';

import DepositBankIcon from '../../assets/images/BankDeposiicon.svg'
import DepositCardIcon from '../../assets/images/CardDepositicon.svg';
import DepositOtherIcon from '../../assets/images/OtherDepositicon.svg';
import DepositCryptoIcon from '../../assets/images/CryptoDepositicon.svg';
import { type TypesubHeaderOptions } from '../../pages/Accounts/DepositSection/DepositComponent';

import styles from './RadioButtonDepositComponent.module.css';

interface IRadioButtonDepositComponent {
  checked: boolean
  value: TypesubHeaderOptions
}

const ImageWrapper = styled('div')(() => ({
  border: 'none',
  borderRadius: '6px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}));

const imageIcons: Record<TypesubHeaderOptions, { icon: string, id: string }> = {
  Crypto: { icon: DepositCryptoIcon, id: 'CryptoDepositIcon' },
  Card: { icon: DepositCardIcon, id: 'CardDepositIcon' },
  Bank: { icon: DepositBankIcon, id: 'BankDepositIcon' },
  Other: { icon: DepositOtherIcon, id: 'OtherDepositIcon' }
};

export const RadioButtonDepositComponent: React.FC<IRadioButtonDepositComponent> = ({
  checked,
  value
}) => {
  const iconImage = imageIcons[value] ?? imageIcons.Other;
  const color = checked ? 'var(--CFD-theme-System-Primary)' : 'var(--CFD-theme-System-OnSurfaceVariant)';

  return (
    <ImageWrapper role="button">
      <div className={ styles.btnIcon } style={{ color }}>
        <svg className={ styles.svgSize }>
          <use xlinkHref={`${iconImage.icon}#${iconImage.id}`}/>
        </svg>
      </div>
    </ImageWrapper>
  )
}
