import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type PayloadInterface } from '../../interfaces';
import { apiHelper } from '../api/apiHelper';

export interface ITransactionHistory {
  amount: number
  asset: string
  createdAt: string
  isResolved: number
  comment: string
  status: string
  statusColor: string
  transactionType: boolean
  type: string
  fromAccount: string
  toAccount: string
  _id: string
  customId: number
}

const initialState: ITransactionHistory[] = [];

interface GetTransactionsHistoryPropsType {
  userId: string
  accountId: string
  sortField?: string
  sortOrder?: string
}

export const getTransactionsHistory = createAsyncThunk(
  'transactions-history/get',
  async ({ userId, accountId, sortField = 'createdAt', sortOrder = 'desc' }: GetTransactionsHistoryPropsType) => {
    const response = await apiHelper({
      method: 'get',
      path: `/transaction/user-history?userId=${userId}&accountId=${accountId}&sortField=${sortField}&sortOrder=${sortOrder}`
    });
    return response.data.transactions;
  }
);

export const createTransaction = createAsyncThunk(
  'transaction/create',
  async (transactionData: object, { rejectWithValue, fulfillWithValue }) => {
    try {
      await apiHelper({ method: 'post', path: '/transaction/deposit-req', data: transactionData });

      const successPayload: PayloadInterface = {
        data: 'Transaction was successfully created',
        isError: false
      }

      return fulfillWithValue(successPayload);
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const createExternalTransaction = createAsyncThunk(
  'transaction/external/create',
  async (transactionData: object, { rejectWithValue, fulfillWithValue }) => {
    try {
      await apiHelper({ method: 'post', path: '/externalTransaction/withdraw-coins', data: transactionData });

      const successPayload: PayloadInterface = {
        data: 'Withdrawal was successfully created',
        isError: false
      }

      return fulfillWithValue(successPayload);
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const transactionsHistorySlice = createSlice({
  name: 'transactionsHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionsHistory.fulfilled, (state, action: PayloadAction<ITransactionHistory[]>) => {
      return action.payload;
    });
  }
});

export default transactionsHistorySlice.reducer;
