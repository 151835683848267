import React, { type FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import { ModalContext } from '..';
import { HeaderModalAutoTrader } from './Header/HeaderModalAutoTrader';
import { SectionModalAutoTrader } from './Section/SectionModalAutoTrader';
import { FooterModalAutoTrader } from './Footer/FooterModalAutoTrader';
import { getAutotrader } from '../../redux/reducers/autotrader';

interface ModalAutoTraderInterface {
  open: boolean
  setOpen: (el: boolean) => void
}

export const ModalAutoTrader: FC<ModalAutoTraderInterface> = ({ open, setOpen }) => {
  const [step, setStep] = useState<number>(1);
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);

  const handleClose = (value: boolean): void => {
    setOpen(value);
  }

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(getAutotrader(userId));
    }
  }, [userId]);

  return (
    <ModalContext
      open={ open }
      setOpen={ handleClose }
      headerComponent={ <HeaderModalAutoTrader setOpen={ handleClose }/> }
      sectionComponent={ <SectionModalAutoTrader step={ step } /> }
      footerComponent={ <FooterModalAutoTrader step={ step } setStep={ setStep } setOpen={ handleClose }/> }
    />
  )
}
