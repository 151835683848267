import React, { type FC } from 'react'
import Avatar from '@mui/material/Avatar';
import style from './UserInfo.module.css'

interface Props {
  userName: string
  email?: string | null
  profileImage: string | null
}

export const UserInfo: FC<Props> = ({ userName, email, profileImage }) => {
  const imageLink = process.env.REACT_APP_SERVER_URL + '/images/' + profileImage
  const avatarStyles = { bgcolor: 'var(--CFD-theme-System-Primary)', width: 44, height: 44, color: 'var(--CFD-theme-System-OnPrimary)', textTransform: 'capitalize' }

  return (
    <div className={style.userInfo}>
      {
        profileImage === null
          ? <Avatar sx={avatarStyles}>{userName[0]}</Avatar>
          : <Avatar sx={avatarStyles} alt={userName} src={imageLink} />
      }
      <div className={style.userAdditionalInfo}>
        <div className={style.userName}>
          <span>{userName}</span>
        </div>
        <div className={style.userEmail}>
          <span>{email ?? ''}</span>
        </div>
      </div>
    </div>
  )
}
