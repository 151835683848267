import React, { type FC } from 'react';

interface IOrderChartIcon {
  cx: number
  cy: number
  iconColor: string
  bgcolor: string
}

export const OrderChartIcon: FC<IOrderChartIcon> = ({ cx, cy, bgcolor, iconColor }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" x={cx - 10} y={cy - 10}>
      <rect width="20" height="20" rx="10" fill={bgcolor} />
      <path d="M6.00039 14V7.55L5.02539 5.425L5.92539 5L7.10039 7.525H12.9004L14.0754 5L14.9754 5.425L14.0004 7.55V14H6.00039ZM9.00039 10.5H11.0004C11.1421 10.5 11.2608 10.4521 11.3566 10.3563C11.4525 10.2604 11.5004 10.1417 11.5004 10C11.5004 9.85833 11.4525 9.73958 11.3566 9.64375C11.2608 9.54792 11.1421 9.5 11.0004 9.5H9.00039C8.85872 9.5 8.73997 9.54792 8.64414 9.64375C8.54831 9.73958 8.50039 9.85833 8.50039 10C8.50039 10.1417 8.54831 10.2604 8.64414 10.3563C8.73997 10.4521 8.85872 10.5 9.00039 10.5ZM7.00039 13H13.0004V8.525H7.00039V13Z" fill={iconColor} />
    </svg>
  )
}
