import React, { type FC, useEffect } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { t } from 'i18next';

import { BinIcon } from '../../../../../components/icons/BinIcon';
import { SwitchComponent, useSnackbar } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { switchOtpStatus2FA } from '../../../../../redux/reducers/twoFactorAuthentication';
import { profileSettingsSelector } from '../../../../../redux/selectors/profileSettings';

import style from './TwoFAContent.module.css';

interface InterfaceTwoFaContent {
  handleBinIcon: () => void
}

export const TwoFAContent: FC<InterfaceTwoFaContent> = ({ handleBinIcon }) => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar()
  const { otpVerifiedAt } = useAppSelector(({ user }) => user);
  const { isGoogleTwoFactorAuthenticationEnabled } = useAppSelector(profileSettingsSelector);

  const prettyDate = new Date((otpVerifiedAt ?? new Date()) as unknown as Date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })

  const switch2FA = (): any => dispatch(switchOtpStatus2FA(handleOpen))

  return (
    <div className={ style.settings2FAVariantWrapper }>
      <div className={ style.settings2FAVariantContainer }>
        <div className={ style.settings2FABtn }>
          <div className={ style.switchContainer }>
            <SwitchComponent checked={isGoogleTwoFactorAuthenticationEnabled} onChange={switch2FA} />
            <p className={ style.settings2FABtnParagraph }>{t('labels.added')}: { prettyDate }</p>
          </div>
          <div className={ style.settings2FAIconContainer }>
            <BinIcon onClick={handleBinIcon} />
          </div>
        </div>
      </div>
    </div>
  )
}
