import React, { type FC } from 'react';
import { TableBody, TableCell, TableRow, styled } from '@mui/material';
import { t } from 'i18next';

import { type ITransactionHistory } from '../../../../../redux/reducers/transactionsHistory';
import { EmptyTableComponent, TooltipComponent } from '../../../../../components';
import { InfoBadge } from '../../../../../components/icons/InfoBadge';
import { bgStatusColor } from '../StyledAccountsTableBody';
import { formatDate } from '../../../../../helpers/util';

import style from './MobileStyledAccountsTableBody.module.css';

const MobileStyledTableRow = styled(TableRow)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface) !important',
  color: 'var(--CFD-theme-System-OnSurface)',
  gap: '8px'
}));

const MobileStyledTableCell = styled(TableCell)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface) !important',
  color: 'var(--CFD-theme-System-OnSurface)',
  paddingTop: '0',
  paddingBottom: '0',
  height: '52px',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)'
}));

interface InterfaceMobileStyledAccountsTableBody {
  rows: ITransactionHistory[]
  setAmount: (row: ITransactionHistory) => string
}

export const MobileStyledAccountsTableBody: FC<InterfaceMobileStyledAccountsTableBody> = ({ rows, setAmount }) => {
  return (
    <>
      { rows.length > 0
        ? (<TableBody>
            {rows.map((row) => (
              <MobileStyledTableRow key={ row._id }>
              <MobileStyledTableCell sx={{ paddingLeft: '16px', paddingRight: '4px' }}>
                <div className={ style.statusWrapper }>
                  <p className={ style.typeText }>{t(`transaction_types.${row.type}`)}</p>
                  <p className={ style.dateText }>{ formatDate(new Date(row.createdAt)) }</p>
                </div>
              </MobileStyledTableCell>
              <MobileStyledTableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '5px', color: row.statusColor }}>
                <div className={ style.statusContainer } style={{ backgroundColor: bgStatusColor[row.status as keyof typeof bgStatusColor] }}>
                  { t(`transaction_statuses.${row.status}`) }
                </div>
                { row.status === 'Declined' && row?.comment !== ''
                  ? (
                      <TooltipComponent title="" content={row?.comment}>
                        <button className={style.infoIconWrapper}>
                          <InfoBadge />
                        </button>
                      </TooltipComponent>
                    )
                  : '' }
              </MobileStyledTableCell>
              <MobileStyledTableCell sx={{ paddingLeft: '24px', paddingRight: '16px' }}>
                <p className={ (Number(setAmount(row)) > 0) ? `${style.rowWrapperTitle} ${style.textGreen}` : `${style.rowWrapperTitle}`}>
                  { setAmount(row) }
                </p>
              </MobileStyledTableCell>
            </MobileStyledTableRow>
            ))}
          </TableBody>)
        : (<EmptyTableComponent columnCount={3} />) }
   </>
  )
}
