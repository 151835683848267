import type { RootState } from '../../redux/store';
import type { KYCVerificationState, VerificationData, VerificationUserKYCProgress } from '../reducers/kycVerification';

export const kycVerificationSelector = (state: RootState): KYCVerificationState => state.kycVerification;
export const kycVerificationDocumentIdCardFrontSelector = (state: RootState): VerificationData => state.kycVerification.idCardFrontData;
export const kycVerificationDocumentIdCardBackSelector = (state: RootState): VerificationData => state.kycVerification.idCardBackData;
export const kycVerificationDocumentSelfieSelector = (state: RootState): VerificationData => state.kycVerification.selfieData;
export const kycVerificationDocumentCreditCardFrontSelector = (state: RootState): VerificationData => state.kycVerification.creditCardFrontData;
export const kycVerificationDocumentCreditCardBackSelector = (state: RootState): VerificationData => state.kycVerification.creditCardBackData;
export const kycVerificationDocumentAddressSelector = (state: RootState): VerificationData => state.kycVerification.addressData;
export const kycVerificationDocumentUserProgress = (state: RootState): VerificationUserKYCProgress => state.kycVerification.userVerificationProgress
