/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';
import { type RootState } from '../store';
import { type KycRequestIdInterface, kycVerificationAPI, type UserVerificationResultInterface } from '../api/kycVerificationApi';
import {
  settingsSetKycVerificationDate,
  settingsSetVerificationStatus,
  settingsVerifyAddress,
  settingsVerifyCreditCard,
  settingsVerifyIdCard,
  settingsVerifySelfie
} from './profileSettings';

export type VerifyStatusType = 'verified' | 'not verified' | 'partially verified'
export type VerificationData = KycRequestIdInterface | null;
export interface VerificationUserKYCProgress {
  totalKycDocsAmount: number
  verifiedPercentage: number
}

export interface KYCVerificationState {
  isUserHaveKYC: boolean
  userVerifyStatus: VerifyStatusType
  isDataLoading: boolean
  idCardFrontData: VerificationData
  idCardBackData: VerificationData
  creditCardFrontData: VerificationData
  creditCardBackData: VerificationData
  selfieData: VerificationData
  addressData: VerificationData
  userVerificationProgress: VerificationUserKYCProgress
}

// Initial state with types applied
const initialState: KYCVerificationState = {
  isUserHaveKYC: false,
  userVerifyStatus: 'not verified',
  isDataLoading: false,
  idCardFrontData: null,
  idCardBackData: null,
  creditCardFrontData: null,
  creditCardBackData: null,
  selfieData: null,
  addressData: null,
  userVerificationProgress: {
    totalKycDocsAmount: 0,
    verifiedPercentage: 0
  }
};

const kycVerificationSlice = createSlice({
  name: 'kycVerification',
  initialState,
  reducers: {
    // Actions to handle state updates
    setUserVerifyStatus (state, action: PayloadAction<VerifyStatusType>) {
      state.userVerifyStatus = action.payload;
    },
    setIsUserHaveKYC (state, action: PayloadAction<boolean>) {
      state.isUserHaveKYC = action.payload;
    },
    // Data setters
    setIdCardFrontData (state, action: PayloadAction<VerificationData>) {
      state.idCardFrontData = action.payload;
    },
    setIdCardBackData (state, action: PayloadAction<VerificationData>) {
      state.idCardBackData = action.payload;
    },
    setCreditCardFrontData (state, action: PayloadAction<VerificationData>) {
      state.creditCardFrontData = action.payload;
    },
    setCreditCardBackData (state, action: PayloadAction<VerificationData>) {
      state.creditCardBackData = action.payload;
    },
    setSelfieData (state, action: PayloadAction<VerificationData>) {
      state.selfieData = action.payload;
    },
    setAddressData (state, action: PayloadAction<VerificationData>) {
      state.addressData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserVerificationData.fulfilled, (state) => {
      state.isDataLoading = false;
    });
    builder.addCase(fetchUserVerificationData.pending, (state) => {
      state.isDataLoading = true;
    });
    builder.addCase(fetchUserVerificationData.rejected, (state) => {
      state.isDataLoading = false;
    });
    builder.addCase(getUserVerificationProgress.fulfilled, (state, action: PayloadAction<VerificationUserKYCProgress>) => {
      state.userVerificationProgress = action.payload;
    });
  }
});

export const {
  setUserVerifyStatus,
  setIsUserHaveKYC,
  setIdCardFrontData,
  setIdCardBackData,
  setCreditCardFrontData,
  setCreditCardBackData,
  setSelfieData,
  setAddressData
} = kycVerificationSlice.actions;

export default kycVerificationSlice.reducer;

const actionUpdateKycMap = new Map<string, (data: VerificationData) => PayloadAction<VerificationData>>([
  ['idCardFront', setIdCardFrontData],
  ['idCardBack', setIdCardBackData],
  ['creditCardFront', setCreditCardFrontData],
  ['creditCardBack', setCreditCardBackData],
  ['selfie', setSelfieData],
  ['address', setAddressData]
]);

export const addVerificationRequest = createAsyncThunk(
  'profile/addVerificationRequest',
  async (data: any, { dispatch }) => {
    let typeOfDocument = '';

    for (const [key] of data.entries()) {
      typeOfDocument = key;
    }

    try {
      const res = await apiHelper({
        method: 'post',
        path: '/kyc-verification',
        data
      });

      const resIds = res.data.kycAmlResult.kycRequestIds as KycRequestIdInterface[];

      if (resIds.length >= 0) {
        const resultKycDocsData = resIds.find((el) => el.type === typeOfDocument);
        if (resultKycDocsData !== undefined) {
          const actionCreator = actionUpdateKycMap.get(typeOfDocument);
          if (actionCreator) dispatch(actionCreator(resultKycDocsData));
        }
      }
    } catch (error: any) {
      console.log(error.response.message)
    }
  }
);

export const getUserVerificationProgress = createAsyncThunk(
  'profile/getUserVerificationProgress',
  async () => {
    try {
      const response = await apiHelper({ method: 'get', path: '/kyc-verification/user-verification-progress' });

      return response.data.progressVerification;
    } catch (error: any) {
      console.log(error.response.message)
    }
  }
);

export const fetchUserVerificationData = createAsyncThunk(
  'profile/fetchUserVerificationData',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { user: { _id } } = getState() as RootState
      const userId = _id ?? ''

      if (userId === '') {
        throw new Error('User id is not provided')
      }

      const userData: UserVerificationResultInterface | null = await kycVerificationAPI.getUserVerificationData(userId)

      if (userData) {
        const kycRequests: KycRequestIdInterface[] = userData.kycRequestIds ?? []
        const verifiedStatus: VerifyStatusType = userData.verifiedStatus
        const verifyCreatedAt: string = userData.createdAt

        dispatch(setIsUserHaveKYC(true))
        dispatch(setUserVerifyStatus(verifiedStatus))
        dispatch(settingsSetKycVerificationDate(new Date(verifyCreatedAt).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })))

        // ??? add 'On Inspect' status in a future
        if (verifiedStatus === 'verified') {
          dispatch(settingsSetVerificationStatus('Verified'))
        } else if (verifiedStatus === 'partially verified') {
          dispatch(settingsSetVerificationStatus('Partially Verified'))
        } else {
          dispatch(settingsSetVerificationStatus('Not Verified'))
        }

        const idCardCondition = ['idCardFront', 'idCardBack']
        const creditCardCondition = ['creditCardFront', 'creditCardBack']
        const selfieCondition = ['selfie']
        const addressCondition = ['address']

        const verifiedIdCard = kycRequests.filter((kycRequest: KycRequestIdInterface) =>
          idCardCondition.includes(kycRequest.type) && kycRequest.verifiedStatus === 'verified'
        )
        const verifiedCreditCard = kycRequests.filter((kycRequest: KycRequestIdInterface) =>
          creditCardCondition.includes(kycRequest.type) && kycRequest.verifiedStatus === 'verified'
        )
        const verifiedSelfie = kycRequests.filter((kycRequest: KycRequestIdInterface) =>
          selfieCondition.includes(kycRequest.type) && kycRequest.verifiedStatus === 'verified'
        )
        const verifiedAddress = kycRequests.filter((kycRequest: KycRequestIdInterface) =>
          addressCondition.includes(kycRequest.type) && kycRequest.verifiedStatus === 'verified'
        )

        const isIdCardVerified = verifiedIdCard.length === 2
        const isCreditCardVerified = verifiedCreditCard.length === 2
        const isSelfieVerified = verifiedSelfie.length === 1
        const isAddressVerified = verifiedAddress.length === 1

        dispatch(settingsVerifyIdCard(isIdCardVerified))
        dispatch(settingsVerifyCreditCard(isCreditCardVerified))
        dispatch(settingsVerifySelfie(isSelfieVerified))
        dispatch(settingsVerifyAddress(isAddressVerified))

        kycRequests.forEach((kycReq: KycRequestIdInterface) => {
          switch (kycReq.type) {
            case 'idCardFront':
              dispatch(setIdCardFrontData(kycReq))
              break
            case 'idCardBack':
              dispatch(setIdCardBackData(kycReq))
              break
            case 'creditCardFront':
              dispatch(setCreditCardFrontData(kycReq))
              break
            case 'creditCardBack':
              dispatch(setCreditCardBackData(kycReq))
              break
            case 'selfie':
              dispatch(setSelfieData(kycReq))
              break
            case 'address':
              dispatch(setAddressData(kycReq))
              break

            default:
              break
          }
        });
      } else if (userData === null) {
        dispatch(setIsUserHaveKYC(false))
        dispatch(setUserVerifyStatus('not verified'))
        dispatch(settingsSetKycVerificationDate(''))
        dispatch(settingsSetVerificationStatus('Not Verified'))

        dispatch(settingsVerifyIdCard(false))
        dispatch(settingsVerifyCreditCard(false))
        dispatch(settingsVerifySelfie(false))
        dispatch(settingsVerifyAddress(false))

        dispatch(setIdCardFrontData(null))
        dispatch(setIdCardBackData(null))
        dispatch(setCreditCardFrontData(null))
        dispatch(setCreditCardBackData(null))
        dispatch(setSelfieData(null))
        dispatch(setAddressData(null))
      }
    } catch (error: any) {
      console.log(error.response.message)
    }
  }
);
