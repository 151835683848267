import React, { type FC, useState, useMemo, useEffect } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { selectLanguage, selectLanguages } from '../../../../redux/selectors/language';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { setLanguage } from '../../../../redux/reducers/language';
import { getAllCurrencies } from '../../../../redux/reducers/currencies';
import { SelectComponent, ThemeSelectComponent } from '../../../../components';

import styles from './PlatformSettings.module.css';

interface IPlatformSettings {
  setProfileCurrencySymbol: (el: string) => void
}

const localStorageProfileCurrency = 'profile_currency';

export const PlatformSettings: FC<IPlatformSettings> = ({ setProfileCurrencySymbol }) => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const activeLanguage = useAppSelector(selectLanguage);
  const languages = useAppSelector(selectLanguages);
  const currencies = useAppSelector((state) => state.currencies);

  const profileCurrency = localStorage.getItem(localStorageProfileCurrency);
  const [profileCurrencySelect, setProfileCurrency] = useState<string>('');

  const setLocalStorageProfileCurrency = (symbol: string): void => {
    localStorage.setItem(localStorageProfileCurrency, symbol);
  }

  const languageOptions = useMemo(() => {
    return languages.map((lang) => ({ label: lang.language, value: lang._id, symbol: lang.icon }))
  }, [languages]);

  const currencyOptions = useMemo(() => {
    return currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }));
  }, [currencies]);

  const currentLanguage = useMemo(() => {
    return languages.find((lang) => (lang.code === activeLanguage));
  }, [languages, activeLanguage]);

  const handleLanguageChange = (value: string): void => {
    const languageSymbol = languages.find((lang) => lang._id === value)?.code;
    if (languageSymbol === undefined) return;
    i18n.changeLanguage(languageSymbol);
    dispatch(setLanguage(languageSymbol));
  };

  const handleCurrencyChange = (value: string): void => {
    const currentCurrencies = currencies.find((currency) => currency._id === value);
    if (currentCurrencies === undefined) return;
    setLocalStorageProfileCurrency(currentCurrencies?.symbol ?? '');
    setProfileCurrency(currentCurrencies?._id ?? '');
    setProfileCurrencySymbol(currentCurrencies.symbol ?? '');
  }

  useEffect(() => {
    dispatch(getAllCurrencies());
    if (profileCurrency === null) setLocalStorageProfileCurrency('USD');
  }, []);

  useEffect(() => {
    if (currencies === undefined || profileCurrency === null) return;
    const currentCurrencies = currencies.find((currency) => currency.symbol === profileCurrency);
    setProfileCurrency(currentCurrencies?._id ?? '');
    setProfileCurrencySymbol(currentCurrencies?.symbol ?? '');
  }, [currencies]);

  return (
    <div className={styles.platformSettingsWrapper}>
      <div className={styles.languageContainer}>
        <p className={styles.platformSettingsTitle}>{t('labels.language')}</p>
        <div className={styles.selectContainer}>
          <SelectComponent
            options={languageOptions}
            languageOptions
            onChange={({ target: { value } }) => { handleLanguageChange(value as string); }}
            value={currentLanguage?._id ?? ''}
          />
        </div>
      </div>
      <div className={styles.currencyContainer}>
        <p className={styles.platformSettingsTitle}>{t('labels.dashboard_currency')}</p>
        <div className={styles.selectContainer}>
          <SelectComponent
            options={currencyOptions}
            onChange={({ target: { value } }) => { handleCurrencyChange(value as string); }}
            value={profileCurrencySelect}
          />
        </div>
      </div>
      <div className={styles.appearanceContainer}>
        <p className={styles.platformSettingsTitle}>{t('labels.appearance')}</p>
        <div className={styles.selectContainer}>
          <ThemeSelectComponent />
        </div>
      </div>
    </div>
  );
}
