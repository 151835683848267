import React, { useState, type FC } from 'react';

import { ModalContext } from '../ModalContext/ModalContext';
import { HeaderModalVerifications } from './Header/HeaderModalVerifications';
import { SectionModalVerifications } from './Section/SectionModalVerifications';
import { FooterModalVerifications } from './Footer/FooterModalVerifications'
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { useAppSelector } from '../../hooks/redux';
import { selectKycTypes } from '../../redux/selectors/kycTypes';
import { type KycType } from '../../redux/reducers/kycTypes';

interface IModalVerifications {
  open: boolean
  setOpen: (el: boolean) => void
}

type TModalVerifications = IModalVerifications

export const ModalVerifications: FC<TModalVerifications> = ({ open, setOpen }) => {
  useBodyOverflow(open);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const requiredKycTypes = useAppSelector(selectKycTypes);

  const getCurrentKycType = (index: number): KycType => {
    return requiredKycTypes[index];
  };

  return (
    <ModalContext
      setOpen={setOpen}
      open={open}
      headerComponent={
        <HeaderModalVerifications currentIndex={currentIndex} totalSteps={requiredKycTypes.length} currentKycType={getCurrentKycType(currentIndex)} setIsOpen={setOpen} />
      }
      sectionComponent={
        <SectionModalVerifications currentKycType={ getCurrentKycType(currentIndex) } />
      }
      footerComponent={
        <FooterModalVerifications
          currentIndex={currentIndex}
          totalSteps={requiredKycTypes.length}
          setCurrentIndex={setCurrentIndex}
          setIsOpen={setOpen}
        />
      }
    />
  )
}
