import React, { type FC, useState, type ChangeEvent, useEffect, type FocusEvent, type Dispatch, type SetStateAction } from 'react';
import { InputComponent } from '../../../../components';
import { t } from 'i18next';
import { type IInteracData } from '../InteracWithdrawalSection/InteracWithdrawSection';

import style from './AddInteracFormComponent.module.css';

interface InterfaceAddInteracFormComponent {
  interacData: IInteracData
  setInteracData: Dispatch<SetStateAction<IInteracData>>
  onFocus: (event: FocusEvent<HTMLInputElement>) => void
}

export const AddInteracFormComponent: FC<InterfaceAddInteracFormComponent> = ({
  interacData,
  setInteracData,
  onFocus
}) => {
  const [emailError, setEmailError] = useState<boolean>(false);

  const emailHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value: emailValue } } = event;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setInteracData({ ...interacData, email: emailValue });
    setEmailError(!emailPattern.test(emailValue));
  };

  return (
        <div className={ style.wrapper }>
            <div className={ style.formContainer }>
                <div className={ style.inputContainer }>
                    <label className={ style.label }>{t('labels.email')}</label>
                    <InputComponent
                        onFocus={ onFocus }
                        value={ interacData.email }
                        setValue={ (emailValue) => { setInteracData((prevSate) => ({ ...prevSate, email: emailValue })); } }
                        errorText={t('labels.email_error')}
                        error={ emailError }
                        onChange={ emailHandleChange }
                        placeholder={ `${t('labels.enter_email')}` }
                    />
                </div>
                <div className={ style.inputContainer }>
                    <label className={ style.label }>{t('labels.bank_name')}</label>
                    <InputComponent
                        onFocus={ onFocus }
                        value={ interacData.bankName }
                        setValue={ (bankNameValue) => { setInteracData((prevSate) => ({ ...prevSate, bankName: bankNameValue })); } }
                        placeholder={ `${t('labels.enter_bank_name')}` }
                    />
                </div>
                <div className={ style.inputContainer }>
                    <label className={ style.label }>{t('labels.customer_name')}</label>
                    <InputComponent
                        onFocus={ onFocus }
                        value={ interacData.customerName }
                        setValue={ (customerNameValue) => { setInteracData((prevSate) => ({ ...prevSate, customerName: customerNameValue })); } }
                        placeholder={ `${t('labels.enter_customer_name')}` }
                    />
                </div>
            </div>
        </div>
  )
}
