import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchKycTypes } from '../api/kycTypes';

export interface KycType {
  name: string
  isRequired: boolean
  priority: number
  _id?: string
}

export const getKycTypes = createAsyncThunk('kycTypes/getKycTypes', async () => {
  const { data } = await fetchKycTypes();

  return data.kycAmlTypes;
});

const kycTypesSlice = createSlice({
  name: 'kycTypes',
  initialState: {
    types: [] as KycType[],
    loading: false,
    error: null as string | null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKycTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKycTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.types = action.payload;
      })
      .addCase(getKycTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      });
  }
});

export default kycTypesSlice.reducer;
