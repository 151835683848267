import React from 'react';
import { CircularProgress, LinearProgress, Box } from '@mui/material';
import { type Theme, styled } from '@mui/material/styles';
import { type SxProps } from '@mui/system';

interface ProgressInterface {
  type: 'linear' | 'circular'
  variant?: 'indeterminate' | 'determinate'
  value?: number
  sx?: SxProps<Theme> | undefined
}

const CustomizedCircularProgress = styled(CircularProgress)({
  color: 'var(--CFD-theme-System-Tertiary)'
});

const CustomizedLinearProgress = styled(LinearProgress)({
  color: 'var(--CFD-theme-System-Tertiary)',
  width: '100%',
  borderRadius: '12px',
  '&.MuiLinearProgress-root': {
    backgroundColor: 'var(--CFD-theme-System-SecondaryContainer)',
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'var(--CFD-theme-System-Tertiary)',
      borderRadius: '12px'
    }
  }
});

export const ProgressComponent: React.FC<ProgressInterface> = ({ variant = 'indeterminate', type, sx = { width: 'auto' }, ...rest }) => {
  const setProgress = (): JSX.Element => {
    const typeToProgressComponent = {
      linear: <CustomizedLinearProgress variant={variant} sx={sx} {...rest} />,
      circular: <CustomizedCircularProgress variant={variant} sx={sx} {...rest} />
    }

    return typeToProgressComponent[type];
  }

  return (
    <Box>
      { setProgress() }
    </Box>
  );
}
