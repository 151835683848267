/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { profileAPI } from '../api/profileApi';
import { type RootState } from '../store';
import { ENV } from '../../config/config';
import { type IAccount } from './accounts';
import { calculatePercentage, toFixed } from '../../helpers/util';

export interface StatisticBlockValuesInterface {
  value: number
  valueInUSD: number
}

export interface StatisticProfitBlockValuesInterface {
  profitable: number
  total: number
}

export interface StatisticsInterface {
  totalBalance: StatisticBlockValuesInterface
  totalDeposits: StatisticBlockValuesInterface
  totalPnL: StatisticBlockValuesInterface
  profitableOrders: StatisticProfitBlockValuesInterface
}

export type PieChartDataInterface = Array<[string, string | number]>;

export interface TabInterface {
  accountId: string
  currency: string
  symbol: string
  type: string
  amount: number
  isActive: boolean
}

export interface BalanceChartDataItemInterface {
  data: Array<number | null>
  label: string
}

export interface BalanceChartDataInterface {
  _id: string
  type: string
  createdAt: Date | string
  actionAmount: number
  accountCurrency: string
  finalAmount: number
}

export interface ProfileStateInterface {
  completePercentage: number

  tabsData: IAccount[] | null
  selectedTab: IAccount | null

  isBalanceChartDataLoading: boolean
  isBalanceChartErrorOccur: boolean
  balanceChartData: BalanceChartDataInterface[]

  isPieChartDataLoading: boolean
  isPieChartErrorOccur: boolean
  pieData: PieChartDataInterface

  statistics: StatisticsInterface | null
  isStatiscticLoading: boolean
  isStatisticErrorOccur: boolean
}

const initialState: ProfileStateInterface = {
  completePercentage: 0,

  tabsData: null,
  selectedTab: null,

  isBalanceChartDataLoading: false,
  isBalanceChartErrorOccur: false,
  balanceChartData: [],

  isPieChartDataLoading: false,
  isPieChartErrorOccur: false,
  pieData: [['Status', 'Amount'], ['Profit: 0%', 0], ['Loss: 0%', 0]],

  statistics: null,
  isStatiscticLoading: false,
  isStatisticErrorOccur: false
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileCompletePercent: (state, action: PayloadAction<number>) => {
      state.completePercentage = action.payload;
    },
    setBalanceChartTabsData: (state, action: PayloadAction<IAccount[] | null>) => {
      state.tabsData = action.payload;
    },
    setBalanceChartTab: (state, action: PayloadAction<IAccount | null>) => {
      const tab = state.tabsData?.find((tab) => tab._id === action.payload?._id);
      state.tabsData?.forEach((el) => {
        el?._id === tab?._id ? el.isActive = true : el.isActive = false
      })
      state.selectedTab = tab as IAccount;
    },
    setIsBalanceChartDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isBalanceChartDataLoading = action.payload;
    },
    setIsBalanceChartErrorOccur: (state, action: PayloadAction<boolean>) => {
      state.isBalanceChartErrorOccur = action.payload;
    },
    setBalanceChartData: (state, action: PayloadAction<BalanceChartDataInterface[]>) => {
      state.balanceChartData = action.payload;
    },
    setIsPieChartDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isPieChartDataLoading = action.payload;
    },
    setIsPieChartErrorOccur: (state, action: PayloadAction<boolean>) => {
      state.isPieChartErrorOccur = action.payload;
    },
    setPieData: (state, action: PayloadAction<PieChartDataInterface>) => {
      state.pieData = action.payload;
    },
    setStatistics: (state, action: PayloadAction<StatisticsInterface>) => {
      state.statistics = action.payload;
    },
    setIsStatisticsErrorOccur: (state, action: PayloadAction<boolean>) => {
      state.isStatisticErrorOccur = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPieChartData.fulfilled, (state) => {
      state.isPieChartDataLoading = false;
    });
    builder.addCase(getPieChartData.pending, (state) => {
      state.isPieChartDataLoading = true;
      state.isPieChartErrorOccur = false;
    });
    builder.addCase(getPieChartData.rejected, (state) => {
      state.isPieChartDataLoading = false;
      state.isPieChartErrorOccur = true;
    });

    builder.addCase(getBalanceChartData.fulfilled, (state) => {
      state.isBalanceChartDataLoading = false;
    });
    builder.addCase(getBalanceChartData.pending, (state) => {
      state.isBalanceChartDataLoading = true;
      state.isBalanceChartErrorOccur = false;
    });
    builder.addCase(getBalanceChartData.rejected, (state) => {
      state.isBalanceChartDataLoading = false;
      state.isBalanceChartErrorOccur = true;
    });
    builder.addCase(getStatistic.pending, (state) => {
      state.isStatiscticLoading = true;
      state.isStatisticErrorOccur = false;
    });
    builder.addCase(getStatistic.rejected, (state) => {
      state.isStatiscticLoading = false;
      state.isStatisticErrorOccur = true;
    });
    builder.addCase(getStatistic.fulfilled, (state) => {
      state.isStatiscticLoading = false;
    });
  }
});

export const {
  setProfileCompletePercent,
  setBalanceChartTabsData,
  setBalanceChartTab,
  setIsBalanceChartDataLoading,
  setIsBalanceChartErrorOccur,
  setBalanceChartData,
  setIsPieChartDataLoading,
  setIsPieChartErrorOccur,
  setPieData,
  setStatistics
} = profileSlice.actions;

export default profileSlice.reducer;

export const getProfileCompletePercent = createAsyncThunk(
  'profile/getProfileCompletePercent',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { user: { email, profileImage, countryCode, phone } } = getState() as RootState
      const { profileCompletionCriteria } = ENV

      let profPercent = 0

      if (email) {
        profPercent += profileCompletionCriteria.email
      }
      if (countryCode) {
        profPercent += profileCompletionCriteria.address
      }
      if (phone) {
        profPercent += profileCompletionCriteria.phoneNumber
      }
      if (profileImage) {
        profPercent += profileCompletionCriteria.profilePicture
        // setImagePath(`${process.env.REACT_APP_SERVER_URL}/images/${fullUserData?.profileImage}`)
      }
      // ??? Add in future
      // if (userAnalytics?.bankOrCardWithdrawalAccount) {
      //   profPercent += profileCompletionCriteria.bankOrCardForWithdrawal
      // }
      // if (userAnalytics?.firstDeposit) {
      //   profPercent += profileCompletionCriteria.firstDeposit
      // }

      dispatch(setProfileCompletePercent(profPercent))
    } catch (error) {
      console.dir(error);
    }
  }
);

export const getTabsData = createAsyncThunk(
  'profile/getTabsData',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { accounts } = getState() as RootState

      const sortedByActiveAccounts = [...accounts].sort((a, b) => +b.isActive - +a.isActive);

      dispatch(setBalanceChartTabsData(sortedByActiveAccounts))
      dispatch(setBalanceChartTab(sortedByActiveAccounts[0]))
    } catch (error) {
      console.log(error);
    }
  }
);

export const getBalanceChartData = createAsyncThunk(
  'profile/getBalanceChartData',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { profile: { selectedTab } } = getState() as RootState
      const selectedAccountId = selectedTab?._id

      if (selectedAccountId === undefined) {
        throw new Error('Account id is not selected')
      }

      const data: BalanceChartDataInterface[] = await profileAPI.getBalanceChartData(selectedTab?._id ?? '', selectedTab?.cfdAccountCurrency.symbol ?? '');

      dispatch(setBalanceChartData(data));
    } catch (error) {
      console.dir(error);
      dispatch(setIsBalanceChartErrorOccur(true));
    }
  }
);

export const getPieChartData = createAsyncThunk('profile/getPieChartData', async (_: undefined, { dispatch, getState }) => {
  try {
    const { profile: { selectedTab } } = getState() as RootState
    const selectedAccountId: string | undefined = selectedTab?._id

    if (selectedAccountId === undefined) {
      throw new Error('Account id is not selected')
    }

    const data: any = await profileAPI.getPieChartData(selectedAccountId)
    const pieChartData: PieChartDataInterface = [['Status', 'Amount']]

    const totalAmount = data.GreatThanZeroAmount + data.LowerThanZeroAmount;
    const { GreatThanZeroAmount, LowerThanZeroAmount } = data;
    const valueDelimiter = 2;

    if (GreatThanZeroAmount > 0) {
      const percentage = calculatePercentage(Number(GreatThanZeroAmount), Number(totalAmount));
      const profitValue = `Profit: ${toFixed(percentage, valueDelimiter)}%`
      pieChartData[1] = [profitValue, percentage];
    } else {
      const profitValue = `Profit: ${0}%`;
      pieChartData[1] = [profitValue, 0];
    }

    if (LowerThanZeroAmount > 0) {
      const percentage = calculatePercentage(Number(LowerThanZeroAmount), Number(totalAmount));
      const lossValue = `Loss: ${toFixed(percentage, valueDelimiter)}%`
      pieChartData[2] = [lossValue, percentage];
    } else {
      const lossValue = `Loss: ${0}%`;
      pieChartData[2] = [lossValue, 0];
    }

    dispatch(setPieData(pieChartData));
  } catch (error) {
    console.dir(error);
    dispatch(setIsPieChartErrorOccur(true));
  }
});

export const getStatistic = createAsyncThunk(
  'profile/getStatistic',
  async (profileCurrency: string, { dispatch }) => {
    try {
      const statistics: StatisticsInterface = await profileAPI.getAccountStatistics(profileCurrency);

      dispatch(setStatistics(statistics));
    } catch (error) {
      console.dir(error);
      dispatch(setIsPieChartErrorOccur(true));
    }
  }
)
