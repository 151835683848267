import React, { type FC } from 'react'
import { t } from 'i18next'

import { CurrencyTabs } from './CurrencyTabs/CurrencyTabs'

import style from './BalanceChart.module.css'

export type TabsDataType = TabDataType[]
export interface TabDataType {
  currency: string
  symbol: string
  amount: number
  XAxis: string[]
  chart: ChartData[]
}

interface ChartData {
  data: Array<number | null>
  label: string
  connectNulls?: boolean
  area?: boolean
  showMark?: boolean
  color?: string
}

export const BalanceChart: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.title}>
        {t('profile_page.labels.balance_chart')}
      </div>
      <div className={style.content}>
        <CurrencyTabs />
      </div>
    </div>
  )
}
