export const valueOutputCutLength = {
  comment: 60
};

export const prettyCutOutput = (value: string, field: keyof typeof valueOutputCutLength): string => {
  if (!value) return '-';
  const maxLength = valueOutputCutLength[field];
  const trimmedValue = value.length >= maxLength ? `${value.slice(0, maxLength)}...` : value;
  return trimmedValue ?? value;
};
