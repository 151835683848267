import React, { type HTMLAttributes, type FC, useEffect } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAppSelector } from '../../../../../hooks/redux';
import { selectUserQrCode, selectUserSecret } from '../../../../../redux/selectors/user';
import { useSnackbar } from '../../../../Snackbar/Snackbar';
import styles from './Step1SectionModal2FA.module.css';
import { t } from 'i18next';
import { ProgressComponent } from '../../../../ProgressComponent/ProgressComponent';
import { useDispatch } from 'react-redux';
import { getUserQrCode } from '../../../../../redux/reducers/user';

type TStep1SectionModal2FA = HTMLAttributes<HTMLElement>

export const Step1SectionModal2FA: FC<TStep1SectionModal2FA> = () => {
  const dispatch = useDispatch()
  const { handleOpen } = useSnackbar()

  const userQrCode = useAppSelector(selectUserQrCode)
  const userSecret = useAppSelector(selectUserSecret)

  const appStoreAppLink = process.env.REACT_APP_GOOGLE_AUTH_APP_APP_STORE_LINK
  const playMarketAppLink = process.env.REACT_APP_GOOGLE_AUTH_APP_PLAY_MARKET_LINK

  useEffect(() => {
    if (userQrCode === '') {
      dispatch(getUserQrCode() as any);
    }
  }, [dispatch, userQrCode]);

  const copyInputValue = (): void => {
    navigator.clipboard.writeText(userSecret)

    handleOpen({
      message: 'Copied',
      actionText: '',
      severity: 'success'
    })
  }

  return (
    <section className={ styles.dialogContent }>
        <div className={ styles.dialogContentTop }>
            <p className={ styles.dialogContentTopText }>1. {t('profile_page.2fa_modal.section.step1.titles.download')}</p>

            <div className={ styles.dialogContentTopTextContent }>
                <a href={playMarketAppLink} target='_blank' rel='noreferrer' className={ styles.dialogContentTopTextAndroid }>
                    <OpenInNewIcon sx={{ color: 'var(--CFD-theme-System-Tertiary)', cursor: 'pointer' }} fontSize='small'/> {t('labels.android_app')}
                </a>
                <a href={appStoreAppLink} target='_blank' rel='noreferrer' className={ styles.dialogContentTopTextIOS }>
                    <OpenInNewIcon sx={{ color: 'var(--CFD-theme-System-Tertiary)', cursor: 'pointer' }} fontSize='small'/> {t('labels.ios_app')}
                </a>
            </div>

            <p className={ styles.dialogContentTopText2 }>2. {t('profile_page.2fa_modal.section.step1.titles.get_code')}</p>
        </div>

        <p className={ styles.dialogContentText2 }>{t('profile_page.2fa_modal.section.step1.subtitle')}</p>

        <div className={ styles.dialogContentMiddle }>
            <div className={ styles.inputWrapper }>
                <input className={ styles.input } value={userSecret} disabled/>
                <span className={ styles.copyIcon }>
                    <FileCopyIcon onClick={ copyInputValue } sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />
                </span>
            </div>
            <div className={ styles.qrContainer }>
                {userQrCode === ''
                  ? <ProgressComponent type='circular' />
                  : (
                        <img
                            className={styles.qr}
                            alt='QRCode'
                            src={userQrCode}
                        />
                    )
                }
            </div>
            <p className={ styles.dialogContentText3 }>3. {t('profile_page.2fa_modal.section.step1.titles.enable_2fa')}</p>
        </div>
    </section>
  )
}
