import React, { useEffect, useMemo, type FC } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { useTheme } from '../../../../components';
import { type PieChartDataInterface } from '../../../../redux/reducers/profile';

interface IDoughnutChart {
  data: PieChartDataInterface
  colors: Record<string, string>
  isMobile: boolean
}

export const DoughnutChart: FC<IDoughnutChart> = ({ data, colors, isMobile = false }) => {
  const { theme } = useTheme();

  const colorText = useMemo(() => {
    const colorTextMap: Record<string, string> = {
      dark: '#E3E1E9',
      light: '#181A21'
    };
    return colorTextMap[theme];
  }, [theme]);

  useEffect(() => {
    const root = am5.Root.new('doughnut_chart_div');

    // Set theme
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        innerRadius: am5.percent(40),
        layout: root.horizontalLayout
      })
    );

    if (root?._logo !== undefined) root._logo.dispose();

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        innerRadius: am5.percent(60)
      })
    );

    const colorSet = am5.ColorSet.new(root, {
      colors: Object.values(colors).map((color) => am5.color(color))
    });

    // console.log('Object.values(colors)', Object.values(colors));

    series.slices.template.setAll({
      tooltipText: '{category}'
    });

    series.set('colors', colorSet);

    // Prepare data for the chart
    const formattedData = data?.slice(1).map(([category, value]) => {
      return {
        category,
        value: Number(value),
        color: am5.color(colorText),
        colorChart: colors[category]
      };
    });

    series.data.setAll(formattedData ?? []);

    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);

    // Dynamically set slice fill color based on the data
    series.slices.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem?.dataContext as any;
      return dataItem?.color || fill;
    });

    // Configure labels to match slice colors
    series.labels.template.setAll({
      forceHidden: true
    });

    const legendData = formattedData?.map(item => ({
      name: item.category, // Use dynamic category name
      color: item.color,
      colorChart: item.colorChart // Use the corresponding color from your data
    }));

    const legend = chart.children.push(am5.Legend.new(root, {
      nameField: 'name', // Bind the name field dynamically
      fillField: 'colorChart', // Bind the fill color dynamically
      strokeField: 'colorChart', // Bind the stroke color dynamically
      centerX: am5.percent(50),
      x: am5.percent(70),
      y: am5.percent(30),
      layout: root.verticalLayout
    }));

    // Set label color
    legend.labels.template.setAll({
      fill: am5.color(colorText),
      fontSize: isMobile ? '14px' : '16px'
    });

    // Set marker color (if different from series color)
    legend.markers.template.setAll({
      width: 15,
      height: 15
    });

    chart.children.moveValue(legend, 1);

    // Set the dynamic data for the legend
    legend.data.setAll(legendData ?? []);

    // Add animations
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, colors, isMobile, colorText]);

  return <div id="doughnut_chart_div" style={{ width: '100%', height: '160px' }} />;
};
