import React, { type HTMLAttributes, type FC, useEffect, useState } from 'react';
import { ButtonComponent } from '../../../components';
import styles from './FooterModalVerifications.module.css';
import { t } from 'i18next';

type SetStateAction<T> = T | ((prevState: T) => T);

interface IFooterModalModalVerifications {
  currentIndex: number
  totalSteps: number
  setCurrentIndex: (el: SetStateAction<number>) => void
  setIsOpen: (el: boolean) => void
};

type TFooterModalVerifications = IFooterModalModalVerifications & HTMLAttributes<HTMLElement>;

export const FooterModalVerifications: FC<TFooterModalVerifications> = ({
  currentIndex,
  totalSteps,
  setCurrentIndex,
  setIsOpen
}) => {
  const handleNextBtn = (): void => {
    setCurrentIndex((prevState: number) => (prevState += 1));
  };

  const handleViewProfileBtn = (): void => {
    setIsOpen(false);
  }

  useEffect(() => () => { setCurrentIndex(0) }, [])

  return (
      <footer className={styles.dialogFooter}>
        {currentIndex < totalSteps
          ? (
            <>
              <div className={styles.dialogFooterButton}>
                <ButtonComponent onClick={handleNextBtn} >
                  {t('labels.next')}
                </ButtonComponent>
              </div>
              <p className={styles.dialogFooterSupportText}>
                {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.footer.subtitle')}
              </p>
            </>
            )
          : <div className={styles.dialogFooterButton}>
              <ButtonComponent onClick={handleViewProfileBtn} variant='outlined'>
                {t('labels.view_profile')}
              </ButtonComponent>
            </div>}
      </footer>
  )
}
