import React, { useEffect, useState, type FC, useMemo } from 'react'
import Box from '@mui/material/Box';
import { t } from 'i18next'

import { DividerComponent } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  getProfileBalanceChartData, getProfileSelectedTab, getProfileTabsData, selectProfileIsBalanceChartErrorOccur
} from '../../../../redux/selectors/profile';
import { getBalanceChartData, setBalanceChartTab } from '../../../../redux/reducers/profile';
import { BalanceStatisticChart } from '../BalanceStatisticChart/BalanceStatisticChart';
import EmptyTableIcon from '../../../../assets/images/emptyTable.svg';
import { ButtonAccountsTableNavigation } from '../../../Accounts/AccountsTable/AccountsTableNavigation/ButtonAccountsTableNavigation/ButtonAccountsTableNavigation';

import style from './CurrencyTabs.module.css'

export interface IFormattedChartData {
  _id: string
  type: string
  createdAt: Date | string
  actionAmount: number
  accountCurrency: string
  tooltipTimeFrame: string
  finalAmount: number
}

function formatDateUTC (dateInput: Date | string, withHours: boolean): string {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  const day = String(date?.getUTCDate()).padStart(2, '0');
  const month = String(date?.getUTCMonth() + 1).padStart(2, '0');
  const year = date?.getUTCFullYear();

  const hours = String(date?.getUTCHours()).padStart(2, '0');
  const minutes = String(date?.getUTCMinutes()).padStart(2, '0');

  return withHours ? `${hours}:${minutes} ${year}-${month}-${day}` : `${year}-${month}-${day}`;
}

export const CurrencyTabs: FC = () => {
  const dispatch = useAppDispatch()
  const [formattedChartData, setFormattedChartData] = useState<IFormattedChartData[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const tabsData = useAppSelector(getProfileTabsData);
  const selectedTab = useAppSelector(getProfileSelectedTab);
  const balanceChartData = useAppSelector(getProfileBalanceChartData);

  const isBalanceChartErrorOccur: boolean = useAppSelector(selectProfileIsBalanceChartErrorOccur)

  const BalanceStatisticChartMemo = useMemo(() => (
    <BalanceStatisticChart
      data={formattedChartData}
      currencySymbol={selectedTab?.cfdAccountCurrency.symbol ?? ''}
      tableWidth={tableWidth}
    />
  ), [formattedChartData, selectedTab, tableWidth]);

  useEffect(() => {
    dispatch(getBalanceChartData())
  }, [selectedTab])

  useEffect(() => {
    if (balanceChartData === undefined) return;

    const formatedData = balanceChartData.map((data) => {
      const result = {
        ...data,
        createdAt: formatDateUTC(data.createdAt, false),
        tooltipTimeFrame: formatDateUTC(data.createdAt, true)
      };
      return result;
    });

    const spaceBetweenIconInChart = 50;
    const tableSize = formatedData.length * spaceBetweenIconInChart;
    const minTableWidth = 600;

    (tableSize > minTableWidth) ? setTableWidth(tableSize) : setTableWidth(minTableWidth);

    setFormattedChartData(formatedData);
  }, [balanceChartData]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box >
        <div className={ style.headerWrapper }>
          <div className={ style.header }>
            {
              tabsData?.map((account) => (
                <ButtonAccountsTableNavigation
                  key={ account?._id }
                  account={ account }
                  selectedAccount={ selectedTab }
                  setSelectedAccount={(value) => { dispatch(setBalanceChartTab(value)) } }
                />
              ))
            }
          </div>
        </div>
      </Box>
      <DividerComponent />
        <div className={style.chartWrapper}>
          {
            isBalanceChartErrorOccur || (balanceChartData.length === 0)
              ? (<div className={style.noDataWrapper}>
                  <div className={style.emptyContainer}>
                    <img src={EmptyTableIcon} alt="no_data" />
                    <span>{t('labels.no_data')}</span>
                    <span>{t('labels.nothing_found')}</span>
                  </div>
                </div>)
              : BalanceStatisticChartMemo
          }
        </div>
    </Box>
  );
}
