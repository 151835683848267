import React, { type FC } from 'react';
import { t } from 'i18next';

import { useAppSelector } from '../../../../../hooks/redux';
import { selectKycTypes } from '../../../../../redux/selectors/kycTypes';
import {
  kycVerificationDocumentIdCardFrontSelector,
  kycVerificationDocumentIdCardBackSelector,
  kycVerificationDocumentSelfieSelector,
  kycVerificationDocumentCreditCardFrontSelector,
  kycVerificationDocumentCreditCardBackSelector,
  kycVerificationDocumentAddressSelector
} from '../../../../../redux/selectors/kycVerification';
import { DocsListLabel } from './DocsListLabel/DocsListLabel';
import { type VerificationData } from '../../../../../redux/reducers/kycVerification';

import style from './DocsList.module.css';

const getDocsList = (
  sectionName: string,
  index: number,
  arrayLength: number,
  uniqueKey: string,
  idCardFront: VerificationData,
  idCardBack: VerificationData,
  creditCardFront: VerificationData,
  creditCardBack: VerificationData,
  selfieDocument: VerificationData,
  addressDocument: VerificationData
): JSX.Element | null => {
  const divStyle = index === arrayLength - 1 ? style.docsProgreesListContentNoBorder : style.docsProgreesListContent;

  switch (sectionName) {
    case 'verifyAddress':
      return (
        <li key={`${uniqueKey}-verifyAddress`} className={style.docsProgreesList}>
          <div className={divStyle}>
            <p className={style.docsProgreesListText}>{t('labels.proof_of_address')}</p>
            <DocsListLabel documentData={addressDocument}/>
          </div>
        </li>
      );
    case 'verifySelfie':
      return (
        <li key={`${uniqueKey}-verifySelfie`} className={style.docsProgreesList}>
          <div className={divStyle}>
            <p className={style.docsProgreesListText}>{t('labels.selfie_photo')}</p>
            <DocsListLabel documentData={selfieDocument}/>
          </div>
        </li>
      );
    case 'verifyDocument':
      return (
        <React.Fragment key={`${uniqueKey}-verifyDocument`}>
          <li key={`${uniqueKey}-verifyDocument-front`} className={style.docsProgreesList}>
            <div className={divStyle}>
              <p className={style.docsProgreesListText}>
                {t('labels.valid_government-issued_document_(front)')}
              </p>
              <DocsListLabel documentData={idCardFront}/>
            </div>
          </li>
          <li key={`${uniqueKey}-verifyDocument-back`} className={style.docsProgreesList}>
            <div className={divStyle}>
              <p className={style.docsProgreesListText}>
                {t('labels.valid_government-issued_document_(back)')}
              </p>
              <DocsListLabel documentData={idCardBack}/>
            </div>
          </li>
        </React.Fragment>
      );
    case 'verifyCard':
      return (
        <React.Fragment key={`${uniqueKey}-verifyCard`}>
          <li key={`${uniqueKey}-verifyCard-front`} className={style.docsProgreesList}>
            <div className={divStyle}>
              <p className={style.docsProgreesListText}>{t('labels.credit_card_(front)')}</p>
              <DocsListLabel documentData={creditCardFront}/>
            </div>
          </li>
          <li key={`${uniqueKey}-verifyCard-back`} className={style.docsProgreesList}>
            <div className={divStyle}>
              <p className={style.docsProgreesListText}>{t('labels.credit_card_(back)')}</p>
              <DocsListLabel documentData={creditCardBack}/>
            </div>
          </li>
        </React.Fragment>
      );
    default:
      return null;
  }
};

export const DocsList: FC = () => {
  const kycTypes = useAppSelector(selectKycTypes);
  const idCardFront = useAppSelector(kycVerificationDocumentIdCardFrontSelector);
  const idCardBack = useAppSelector(kycVerificationDocumentIdCardBackSelector);
  const creditCardFront = useAppSelector(kycVerificationDocumentCreditCardFrontSelector);
  const creditCardBack = useAppSelector(kycVerificationDocumentCreditCardBackSelector);
  const selfieDocument = useAppSelector(kycVerificationDocumentSelfieSelector);
  const addressDocument = useAppSelector(kycVerificationDocumentAddressSelector);

  const filteredKycTypes: Array<{ name: string }> = kycTypes.filter((type) => type.name);

  return (
    <ul className={style.docsProgreesListContainer}>
      {filteredKycTypes.map(({ name }, index) =>
        getDocsList(
          name,
          index,
          filteredKycTypes.length,
          `${name}-${index}`,
          idCardFront,
          idCardBack,
          creditCardFront,
          creditCardBack,
          selfieDocument,
          addressDocument
        )
      )}
    </ul>
  );
};
