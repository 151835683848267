import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type PayloadInterface } from '../../interfaces';
import { apiHelper } from '../api/apiHelper';
import { type UserInterface } from './user';

interface IAccountType {
  _id: string
  name: string
}

interface IAccountCurrency {
  _id: string
  symbol: string
  currencyId: string
  name: string
}

export interface IAccount {
  _id: string
  userId: string
  isActive: boolean
  balance: number
  credit: number
  customId: number
  total: number
  isEnabled: boolean
  cfdAccountCurrency: IAccountCurrency
  symbol: string
  type: IAccountType
  user: UserInterface
}

interface ITransferAmountData {
  fromAccountId: string
  toAccountId: string
  amount: number
  userId: string
}

const initialState: IAccount[] = [];

export const getAccounts = createAsyncThunk(
  'accounts/get',
  async (userId: string) => {
    const url = `?page=1&limit=100&userId=${userId}&isSaving=${JSON.stringify([false])}`;

    const response = await apiHelper({ method: 'get', path: `/cfd-account/platform-query${url}` });

    return response.data.cfdAccounts.cfdAccounts;
  }
);

export const activateAccount = createAsyncThunk(
  'account/activate',
  async ({ accountId, userId }: { accountId: string, userId: string }) => {
    const response = await apiHelper({ method: 'patch', path: `/cfd-account/activate/${accountId}?userId=${userId}` });

    return { message: response.data.message, id: accountId };
  }
);

export const transferAmount = createAsyncThunk(
  'accounts/transfer',
  async (transferData: ITransferAmountData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiHelper({ method: 'patch', path: '/cfd-account/transfer', data: transferData });

      const successPayload: PayloadInterface = {
        data: response.data.message,
        isError: false
      }

      return fulfillWithValue(successPayload);
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setActiveAccount (state, action: PayloadAction<string>) {
      state.forEach(account => {
        account.isActive = account._id === action.payload;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAccounts.fulfilled, (state, action: PayloadAction<IAccount[]>) => {
      return action.payload;
    });
    builder.addCase(activateAccount.fulfilled, (state, action: PayloadAction<Record<string, string>>) => {
      state.forEach(account => {
        account.isActive = account._id === action.payload.id;
      });
    });
  }
});
export const { setActiveAccount } = accountsSlice.actions;
export default accountsSlice.reducer;
