import React, { useContext, type FC } from 'react';
import { t } from 'i18next';

import { TwoFAContent } from './2FAContent/TwoFAContent';
import { setStep2FA } from '../../../../redux/reducers/twoFactorAuthentication';
import { ButtonComponent } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { GlobalDialogContext } from '../../../../context/GlobalDialogContext/GlobalDialogContextProvider';
import { Delete2FA } from '../2FASection/2FAContent/Delete2FADialog/Delete2FA';
import { profileSettingsSelector } from '../../../../redux/selectors/profileSettings';

import style from './TwoFASection.module.css';

interface ITwoFASection {
  setOpen2FAModal: (el: boolean) => void
}

export const TwoFASection: FC<ITwoFASection> = ({ setOpen2FAModal }) => {
  const dispatch = useAppDispatch()
  const { isGoogleTwoFactorAuthenticationDeleted } = useAppSelector(profileSettingsSelector);
  const { showDialog, hideDialog } = useContext(GlobalDialogContext);

  const handleOpen2FA = (): void => {
    dispatch(setStep2FA(1));
    setOpen2FAModal(true);
  }

  const handle2FABinIcon = (): void => {
    showDialog({ headerContent: <Delete2FA hideDialog={hideDialog} /> });
  }

  return (
    <>
      {
        isGoogleTwoFactorAuthenticationDeleted
          ? (<TwoFAContent handleBinIcon={handle2FABinIcon} />)
          : (
              <div className={style.settingsSecondSectionContentContainer}>
                <p className={ style.settingsSecondSectionContentText }>
                  {t('profile_page.settings.complete_profile.google_2fa.subtitle')}
                </p>
                <div className={ style.settingsSecondSectionContentBtnContainer }>
                  <div className={ style.btnSecondContainer}>
                    <ButtonComponent variant='text' onClick={handleOpen2FA}>
                      {t('profile_page.settings.complete_profile.google_2fa.2fa_button_text')}
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            )
      }
    </>

  )
}
