import React, { type InputHTMLAttributes, type FC, useState, type ChangeEvent } from 'react'
import { InputAdornment, TextField, styled } from '@mui/material';
import { Button } from '@mui/base/Button'
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { type SetStateAction, type InterfacePasswordValue } from '../PasswordInputComponent/PasswordInputComponent'

import styles from './CvvInputComponent.module.css';

interface CvvInputInterface {
  id?: string
  value: InterfacePasswordValue
  setValue: (value: SetStateAction<InterfacePasswordValue>) => void
  placeholder?: string
  disabled?: boolean
  sizeInput?: 'small' | 'medium'
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
}

type TCvvInputInput = CvvInputInterface & InputHTMLAttributes<HTMLInputElement>

const IconButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
  color: var(--CFD-theme-System-Outline);
`;

const CustomizedInput = styled(TextField)(({ theme, error, focused }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    paddingLeft: '16px',
    height: '40px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'unset',
    '&.Mui-error': {
      border: '3px solid var(--CFD-theme-System-Error)'
    },
    '&.Mui-focused': {
      border: '3px solid',
      borderColor: (error === true) ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-Primary)'
    },
    '&.Mui-disabled': {
      opacity: '0.3'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: (error === true) ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: '0',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}));

export const CvvInputComponent: FC<TCvvInputInput> = ({
  id,
  value: propsValue,
  setValue,
  disabled,
  sizeInput = 'small',
  required,
  variant = 'outlined'
}) => {
  const [invalidCVV, setInvalidCVV] = useState<boolean>(false);
  let fontSize: 'small' | 'medium' = 'medium';
  if (sizeInput === 'small') fontSize = 'small';

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value: inputValue } } = event;

    if (/^[0-9]{0,4}$/.test(inputValue)) {
      setValue(prevState => ({ ...prevState, value: inputValue }));
      setInvalidCVV(false);
    } else {
      if (inputValue !== '') setInvalidCVV(true);
    }
  };

  const handleClickShowPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    if (setValue != null) setValue((prevState: InterfacePasswordValue) => ({ ...prevState, showPassword: !prevState.showPassword }));
  };

  const handleBlur = (): void => {
    const cvvLenght = propsValue.value.length;
    setInvalidCVV(cvvLenght > 4 || cvvLenght < 3);
  };

  return (
    <div className={ styles.inputContainer }>
      <CustomizedInput
        type={ propsValue.showPassword ? 'text' : 'password' }
        id={ id }
        value={ propsValue.value }
        onChange={ handleChange }
        placeholder='CVV'
        error={ invalidCVV }
        disabled={ disabled }
        size={ sizeInput }
        variant={ variant }
        required={ required }
        onBlur={handleBlur}
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                { propsValue.showPassword
                  ? (<Visibility fontSize={ fontSize } />)
                  : (<VisibilityOff fontSize={ fontSize } />)}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      { invalidCVV ? <p className={ styles.supportingTextError }>Please enter up to 4 digits only</p> : null }
    </div>
  );
};
