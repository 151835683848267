import React, { useEffect, useState } from 'react'

import { BalanceChart } from './BalanceChart/BalanceChart'
import { TradingChart } from './TradingChart/TradingChart'
import { Settings } from './Settings/Settings'
import { Statistic } from './Statistic/Statistic'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { getTabsData } from '../../redux/reducers/profile'
import { ProfileElementHeight } from './helper';
import { useWindowSize } from '../../hooks/useWindowSize';

import style from './Profile.module.css'

const localStorageProfileCurrency = 'profile_currency';

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch()
  const profileCurrencyStorage = localStorage.getItem(localStorageProfileCurrency);
  const [screenWidth, screenHeight] = useWindowSize();
  const [calcHeight, setCalcHeight] = useState<number>(0);
  const [profileCurrencySymbol, setProfileCurrencySymbol] = useState<string>(profileCurrencyStorage ?? '');

  const accounts = useAppSelector((state) => state.accounts);

  const isTableView = screenWidth <= 1145 && screenWidth > 1024;
  const isMobile = screenWidth <= 1024;

  useEffect(() => {
    if (accounts.length !== 0) {
      dispatch(getTabsData())
    }
  }, [accounts])

  useEffect(() => {
    if (screenHeight > 0 && screenWidth > 0) {
      if (!isTableView) {
        const allPageContentHeight = ((ProfileElementHeight.Gap * 2) + ProfileElementHeight.BalanceChartSection +
          ProfileElementHeight.StatisticSection + ProfileElementHeight.TradingChartSection);
        setCalcHeight(Number(allPageContentHeight));
      }
      if (isTableView) {
        const allPageContentHeight = ((ProfileElementHeight.Gap * 2) + ProfileElementHeight.BalanceChartSection +
          ProfileElementHeight.TableStatisticSection + ProfileElementHeight.TradingChartSection);
        setCalcHeight(Number(allPageContentHeight));
      }
    }
  }, [screenHeight, screenWidth]);

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.settingsContainer}>
          <Settings calcHeight={ calcHeight } isMobile={ isMobile } setProfileCurrencySymbol={setProfileCurrencySymbol}/>
        </div>
        <div className={style.profileContainer}>
          <div className={style.statisticContainer}>
            <Statistic profileCurrencySymbol={profileCurrencySymbol}/>
          </div>
          <div className={style.tradingChartContainer}>
            <TradingChart />
          </div>
          <div className={style.balanceChartContainer}>
            <BalanceChart />
          </div>
        </div>
      </div>
    </>
  )
}
