import React, { type FC, type FocusEvent, useEffect, useMemo, useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import { useTheme } from '../ThemeProvider/ThemeProvider';

import styles from './ThemeSelectComponent.module.css';

const CustomizedSelect = styled(Select)(() => ({
  paddingLeft: '16px',
  height: '40px',
  color: 'var(--CFD-theme-System-OnSurfaceVariant)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textAlign: 'left',
  borderRadius: '4px',
  border: '1px solid var(--CFD-theme-System-Outline)',
  outline: 'unset',
  '& .MuiSelect-select': {
    padding: 0
  },
  '&.Mui-focused': {
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '3px solid var(--CFD-theme-System-Primary)'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  }
}));

const CustomizedMenuItem = styled(MenuItem)(() => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: 'var(--CFD-theme-Surface-3)',
      borderRadius: '4px',
      padding: 0
    }
  },
  MenuListProps: {
    sx: {
      maxHeight: '200px'
    }
  }
};

export const ThemeSelectComponent: FC = () => {
  const { theme, toggleTheme } = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(theme ?? '');

  const themeOptions = useMemo(() => [
    {
      value: 'light',
      label: `${t('labels.light')}`
    },
    {
      value: 'dark',
      label: `${t('labels.dark')}`
    }
  ], [t]);

  useEffect(() => {
    setValue(theme);
  }, [theme])

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    setIsFocused(false);
    setIsOpen(false);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleChangeTheme = (value: string): void => {
    if (value === theme) return;
    toggleTheme();
    setValue(value);
  }

  const DefaultSelect = (): JSX.Element => (
    <CustomizedSelect
      MenuProps={MenuProps}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={({ target: { value } }) => { handleChangeTheme(value as string); }}
      renderValue={(selected) => {
        const selectedOption = themeOptions.find(theme => theme.value === selected);
        return (selectedOption !== undefined)
          ? <div className={styles.itemContainer}>
              {
                selectedOption.value === 'dark'
                  ? <DarkModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
                  : <LightModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
              }
              {selectedOption.label}</div>
          : null;
      }}
    >
      {themeOptions.map((theme) => (
        <CustomizedMenuItem key={theme.value} value={theme.value}>
          <div className={styles.itemContainer}>
            {
              theme.value === 'dark'
                ? <DarkModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
                : <LightModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
            }
            {theme.label}
          </div>
        </CustomizedMenuItem>
      ))}
    </CustomizedSelect>
  );

  return (
    <FormControl fullWidth>
      <DefaultSelect />
    </FormControl>
  )
}
