import React, { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { AssetsOptions } from '../../AssetsOptions/AssetsOptions';
import { ButtonComponent, ModalAssetsSubscribe, useSnackbar } from '../../../../../components';
import { createSavingAccount } from '../../../../../redux/reducers/savingAccounts';
import { type PayloadInterface } from '../../../../../interfaces';
import { handleError } from '../../../../../helpers';
import { getAccountIconBySymbol, getImage } from '../../../../../helpers/util';
import { type ISavingsTableCell } from '../../../interface/savingsPageInterfaces';

import style from './SavingsTableCell.module.css';

export const SavingsTableCell: FC<ISavingsTableCell> = ({ row, setSection, isTable }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const { customCurrency, customCurrencyId, currency, options, currencyId } = row;
  const savingAccounts = useAppSelector((state) => state.savingAccounts);
  const { _id: userId } = useAppSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<string>(row.options[0]._id);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isSelectedCurrencyIdPresent = savingAccounts.some((account) => (account.currencyId ?? account.customCurrencyId) === (currencyId ?? customCurrencyId)?.toString());

  const handleChangeOption = (value: string): void => {
    setSelectedAsset(value);
  };

  const handleSubscribe = (): void => {
    setOpenModal(true);
  };

  const handleCreateSavingAccount = async (): Promise<void> => {
    if (userId !== undefined) {
      setIsButtonLoading(true);
      const response = await dispatch(createSavingAccount({ userId, currencyId, customCurrencyId }));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsButtonLoading(false);

        return;
      }
      setIsButtonLoading(false);
    }
  };

  return (
    <div className={ style.tableContainer }>
      <ModalAssetsSubscribe
        open={ openModal }
        setOpen={ setOpenModal }
        saving={ row }
        defaultSelectedAsset={ selectedAsset }
        setSection={ setSection }
      />
      {isTable
        ? (
            <>
              <div className={style.mobileCellHeader}>
                <div className={ style.assetContainer }>
                  <img height="24px" width="24px" src={ currency?.symbol ? getAccountIconBySymbol(currency.symbol ?? '') : getImage(customCurrency?.icon) } />
                  <p className={ style.assetContainerParagraph }>{currency?.symbol ?? customCurrency?.name}</p>
                </div>
                <div className={ style.btnContainer }>
                  {isSelectedCurrencyIdPresent
                    ? <ButtonComponent onClick={ handleSubscribe }>{t('savings_page.table_body.savings_tab.subscription_title')}</ButtonComponent>
                    : <ButtonComponent variant='outlined' loading={isButtonLoading} onClick={() => { handleCreateSavingAccount() }}>{t('savings_page.table_body.savings_tab.create_account_title')}</ButtonComponent>}
                </div>
              </div>
              {
                isSelectedCurrencyIdPresent && (
                  <div className={ style.mobileOptionsWrapper }>
                    <p className={ style.tipsParagraph }>{t('savings_page.table_body.savings_tab.duration_title')}:</p>
                    <div className={ style.mobileOptionsContainer }>
                      {options.map((item) => {
                        return (
                          <div
                            key={item._id}
                            className={style.mobileOptionAssetWrapper}
                            onClick={() => { handleChangeOption(item._id); }}
                            style={{
                              border: `1px solid ${selectedAsset === item._id ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OutlineVariant)'}`
                            }}
                          >
                            <p>{ t(`savings_page.duration_types.${item.label}`) }</p>
                            <p className={ style.mobilePercentSpan }>{ item.percent }%</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            </>
          )
        : (
            <>
              <div className={ style.assetContainer }>
                <img height="24px" width="24px" src={ currency?.symbol ? getAccountIconBySymbol(currency.symbol ?? '') : getImage(customCurrency?.icon) } />
                <p className={ style.assetContainerParagraph }>{currency?.symbol ?? customCurrency?.name}</p>
              </div>
              {
                isSelectedCurrencyIdPresent && (
                  <div className={ style.tipsContainer }>
                    <p className={ style.tipsParagraph }>{t('savings_page.table_body.savings_tab.duration_title')}:</p>
                      <AssetsOptions options={options} selectedAsset={selectedAsset} handleChange={handleChangeOption} />
                  </div>
                )
              }
              <div className={ style.btnContainer }>
                {isSelectedCurrencyIdPresent
                  ? <ButtonComponent onClick={ handleSubscribe }>{t('savings_page.table_body.savings_tab.subscription_title')}</ButtonComponent>
                  : <ButtonComponent variant='outlined' loading={isButtonLoading} onClick={() => { handleCreateSavingAccount() }}>{t('savings_page.table_body.savings_tab.create_account_title')}</ButtonComponent>}
              </div>
            </>
          )
    }
    </div>
  )
}
