import React, {
  type HTMLAttributes,
  type FC
} from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import styles from './HeaderModalVerifications.module.css';
import { t } from 'i18next';
import { type KycType } from '../../../redux/reducers/kycTypes';

interface IHeaderModalVerifications {
  currentIndex: number
  totalSteps: number
  currentKycType: KycType
  setIsOpen: (el: boolean) => void
}

type THeaderModalVerifications = IHeaderModalVerifications & HTMLAttributes<HTMLElement>

export const HeaderModalVerifications: FC<THeaderModalVerifications> = ({ currentIndex, totalSteps, currentKycType, setIsOpen }) => {
  const handleBtnClick = (): void => {
    setIsOpen(false)
  }

  return (
      <header className={styles.dialogHeader}>
        <div className={styles.dialogHeaderContainer}>
          <div className={styles.dialogHeaderContent}>
            <h3 className={styles.dialogHeaderTitle}>
              {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.header.title')}
            </h3>
            {currentIndex < totalSteps
              ? (
                <p className={styles.dialogHeaderText}>
                  {t('labels.step')} {currentIndex + 1}/<span className={styles.dialogHeaderTotalSteps}>{totalSteps}</span> - {t(`profile_page.settings.complete_profile.identity_verifications.statuses.${currentKycType.name}`)}
                </p>
                )
              : (
                <p className={styles.dialogHeaderText}>{t('labels.success')}</p>
                )}
          </div>
          <div className={styles.dialogHeaderBtnContainer}>
            <CloseOutlinedIcon onClick={handleBtnClick} sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }} />
          </div>
        </div>
      </header>
  );
};
