import React, { type FC, type FocusEvent, useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { type SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import { type OptionInterface } from '../../interfaces';
import { SelectComponentValueView } from './components/SelectComponentValueView';

import styles from './SelectComponent.module.css';

interface SelectInterface {
  options: OptionInterface[]
  type?: 'default' | 'search'
  value?: string | any
  id?: string
  height?: number
  onChange?: (event: SelectChangeEvent<unknown>) => void
  onSearch?: (value: string | OptionInterface | null) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  languageOptions?: boolean
}

const CustomizedSelect = styled(Select)(() => ({
  paddingLeft: '16px',
  height: '40px',
  color: 'var(--CFD-theme-System-OnSurfaceVariant)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textAlign: 'left',
  borderRadius: '4px',
  border: '1px solid var(--CFD-theme-System-Outline)',
  outline: 'unset',
  '& .MuiSelect-select': {
    padding: 0
  },
  '&.Mui-focused': {
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '3px solid var(--CFD-theme-System-Primary)'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  }
}));

const CustomizedMenuItem = styled(MenuItem)(() => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: 'var(--CFD-theme-Surface-3)',
      borderRadius: '4px',
      padding: 0
    }
  },
  MenuListProps: {
    sx: {
      maxHeight: '200px'
    }
  }
};

export const SelectComponent: FC<SelectInterface> = ({
  options = [],
  type = 'default',
  id = '',
  onSearch = () => {},
  value = '',
  height,
  onFocus,
  onBlur,
  languageOptions = false,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setIsFocused(true);
    setIsOpen(true);
    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    setIsFocused(false);
    setIsOpen(false);
    onBlur?.(event);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const DefaultSelect = (): JSX.Element => (
    <CustomizedSelect
      sx={{ height: `${height}px` }}
      MenuProps={MenuProps}
      value={value}
      id={id}
      onFocus={handleFocus}
      onBlur={handleBlur}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      renderValue={(selected) => {
        const selectedOption = options.find(option => option.value === selected);
        return selectedOption
          ? <SelectComponentValueView selectedOption={selectedOption} languageOptions={languageOptions}/>
          : null;
      }}
      {...rest}
    >
      {options.map((selectedOption) => (
        <CustomizedMenuItem key={selectedOption.value} value={selectedOption.value}>
          <div className={styles.currencyInfoContainer}>
            <SelectComponentValueView selectedOption={selectedOption} languageOptions={languageOptions}/>
            {selectedOption?.amount && <p className={styles.currencyTitle}>{selectedOption?.amount}</p>}
          </div>
        </CustomizedMenuItem>
      ))}
    </CustomizedSelect>
  );

  return (
    <FormControl fullWidth>
      <DefaultSelect />
    </FormControl>
  )
}
