import React, { type FC } from 'react';

interface IExchangeChartIcon {
  cx: number
  cy: number
  iconColor: string
  bgcolor: string
}

export const ExchangeChartIcon: FC<IExchangeChartIcon> = ({ cx, cy, bgcolor, iconColor }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" x={cx - 10} y={cy - 10}>
      <rect width="20" height="20" rx="10" fill={bgcolor}/>
      <path d="M8.36842 6.23202C8.51642 6.3769 8.51895 6.61433 8.37407 6.76232L7.2518 7.90877C7.16795 7.99443 7.09954 8.06458 7.04404 8.12345C7.12191 8.12482 7.21576 8.125 7.32992 8.125H13.5006C13.7077 8.125 13.8756 8.29289 13.8756 8.5C13.8756 8.70711 13.7077 8.875 13.5006 8.875L7.30342 8.875C7.07493 8.87504 6.86342 8.87507 6.70273 8.85186C6.54798 8.8295 6.27804 8.76417 6.16548 8.48658C6.05452 8.21292 6.197 7.97786 6.28963 7.85205C6.38657 7.72041 6.53556 7.56825 6.6976 7.40276L7.83812 6.23768C7.983 6.08968 8.22042 6.08715 8.36842 6.23202Z" fill={iconColor}/>
      <path d="M12.9572 11.8766C12.8794 11.8752 12.7855 11.875 12.6714 11.875H6.50064C6.29354 11.875 6.12564 11.7071 6.12564 11.5C6.12564 11.2929 6.29354 11.125 6.50064 11.125L12.6979 11.125C12.9264 11.125 13.1379 11.1249 13.2986 11.1481C13.4533 11.1705 13.7232 11.2359 13.8358 11.5134C13.9468 11.7871 13.8043 12.0222 13.7117 12.148C13.6147 12.2796 13.4657 12.4318 13.3036 12.5973L12.1632 13.7623C12.0183 13.9103 11.7809 13.9129 11.6329 13.768C11.4849 13.6231 11.4824 13.3857 11.6272 13.2377L12.7495 12.0912C12.8333 12.0056 12.9017 11.9354 12.9572 11.8766Z" fill={iconColor}/>
    </svg>
  )
}
