import React, { type FC } from 'react';

interface IDepositChartIcon {
  cx: number
  cy: number
  iconColor: string
  bgcolor: string
}

export const DepositChartIcon: FC<IDepositChartIcon> = ({ cx, cy, bgcolor, iconColor }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" x={cx - 10} y={cy - 10}>
      <rect width="20" height="20" rx="10" fill={bgcolor} />
      <path d="M14 13V14H6V13H14Z" fill={iconColor} />
      <path d="M12.125 7.58235L9.29304 10.4143L10.3537 11.475L7.52881 11.4714L7.52527 8.64655L8.58593 9.70721L11.4179 6.87525L12.125 7.58235Z" fill={iconColor} />
    </svg>
  )
}
