import React, { type FC, type ChangeEvent } from 'react'

import { styled, InputAdornment, TextField } from '@mui/material';
import { Button } from '@mui/base/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import styles from './PasswordInputComponent.module.css';

export interface InterfacePasswordValue { value: string, showPassword: boolean }

export type SetStateAction<T> = T | ((prevState: T) => T);

interface PasswordInputInterface {
  id?: string
  value: InterfacePasswordValue
  setValue: (value: SetStateAction<InterfacePasswordValue>) => void
  title?: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  supportTextDynamic?: string
  helperTextStatic?: string
  sizeInput?: 'small' | 'medium' | undefined
  errorText?: string
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
}

type TPasswordInput = PasswordInputInterface

const IconButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
  color: var(--CFD-theme-System-Outline);
  `;

const CustomizedInput = styled(TextField)(({ error }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    paddingLeft: '16px',
    height: '40px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'unset',
    '&.Mui-error': {
      border: '3px solid var(--CFD-theme-System-Error)'
    },
    '&.Mui-focused': {
      border: '3px solid',
      borderColor: (error === true) ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-Primary)'
    },
    '&.Mui-disabled': {
      opacity: '0.3'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: (error === true) ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: '0',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}));

export const PasswordInputComponent: FC<TPasswordInput> = ({
  value,
  id,
  setValue,
  title,
  placeholder,
  error,
  disabled,
  helperTextStatic,
  supportTextDynamic,
  sizeInput = 'medium',
  errorText,
  required,
  variant = 'outlined'
}) => {
  let fontSize: 'small' | 'medium';

  if (sizeInput === 'small') fontSize = 'small';
  else fontSize = 'medium';

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (setValue != null) setValue((prevState: InterfacePasswordValue) => ({ ...prevState, value: event.target.value }));
  };

  const handleClickShowPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    if (setValue != null) setValue((prevState: InterfacePasswordValue) => ({ ...prevState, showPassword: !prevState.showPassword }));
  };

  return (
    <div className={ styles.inputContainer }>
      <p className={ `${styles.inputTitle} ${(disabled ?? false) ? styles.disabled : ''}` }>{title}</p>
      <CustomizedInput
        id={ id }
        value={ value.value }
        type={value.showPassword ? 'text' : 'password'}
        onChange={ handleChange }
        placeholder={ placeholder }
        error={ error }
        disabled={ disabled }
        size={ sizeInput }
        variant={ variant }
        required={ required }
        autoComplete="new-password"
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        helperText= { helperTextStatic }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {value.showPassword
                ? (<Visibility fontSize={ fontSize } />)
                : (<VisibilityOff fontSize={ fontSize } />)}
            </IconButton>
            </InputAdornment>
          )
        }}
      />
      {((supportTextDynamic != null) && (value.value.length === 0) && !(error ?? false)) ? <p className={ `${styles.supportingText} ${(disabled ?? false) ? styles.disabled : ''}` }>{supportTextDynamic}</p> : null }
      {((error ?? false) && (errorText != null)) ? <p className={ styles.supportingTextError }>{errorText}</p> : null }
    </div>
  )
}
