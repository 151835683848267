import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type PayloadInterface } from '../../interfaces';
import { apiHelper } from '../api/apiHelper';
import { type ICurrency } from './currencies';

export interface IWallet {
  _id: string
  symbol: string
  address: string
  networkId: string
  currency: ICurrency
  currencyId: string
}

export interface IBank {
  _id: string
  name: string
  iban: string
  accountNumber: string
  bankAddress: string
  swiftCode: string
  currencyId: ICurrency
}

export interface ICard {
  _id: string
  name: string
  card: string
  currencyId: ICurrency
}

interface IWithdrawalMethods {
  cards: ICard[]
  banks: IBank[]
  wallets: IWallet[]
}

const initialState: IWithdrawalMethods = {
  cards: [],
  banks: [],
  wallets: []
}

export const getWallets = createAsyncThunk(
  'withdrawalMethods/wallets/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/external-wallet/${userId}` });

    return response.data.externalWallet;
  }
);

export const getBanks = createAsyncThunk(
  'withdrawalMethods/banks/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/bank-account/${userId}` });

    return response.data.bankAccount;
  }
);

export const getCards = createAsyncThunk(
  'withdrawalMethods/cards/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/credit-card/${userId}` });

    return response.data.creditCards;
  }
);

export const addCard = createAsyncThunk(
  'withdrawalMethods/card/create',
  async (cardData: object, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/credit-card/add', data: cardData });

      return response.data.creditCard;
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const deleteCard = createAsyncThunk(
  'withdrawalMethods/card/delete',
  async (_id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'delete', path: `/credit-card/${_id}` });
      const result: boolean = response.data.success ?? false;

      if (result) {
        dispatch(deleteCardById(_id))
      }
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const addBank = createAsyncThunk(
  'withdrawalMethods/bank/create',
  async (bankData: object, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/bank-account/add', data: bankData });

      return response.data.bankAccount;
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const deleteBank = createAsyncThunk(
  'withdrawalMethods/bank/delete',
  async (_id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'delete', path: `/bank-account/${_id}` });
      const result: boolean = response.data.success ?? false;

      if (result) {
        dispatch(deleteBankById(_id))
      }
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const addWallet = createAsyncThunk(
  'withdrawalMethods/wallet/create',
  async (walletData: object, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/external-wallet/add', data: walletData });

      return response.data.externalWallet;
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const deleteWallet = createAsyncThunk(
  'withdrawalMethods/wallet/delete',
  async (_id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'delete', path: `/external-wallet/${_id}` });
      const result: boolean = response.data.success ?? false;

      if (result) {
        dispatch(deleteWalletById(_id))
      }
    } catch (error: any) {
      const { data } = error.response;
      const isValidationError = Object.hasOwnProperty.call(data, 'error');
      const message = !isValidationError ? data.message : data.error;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const withdrawalsMethodsSlice = createSlice({
  name: 'withdrawalMethods',
  initialState,
  reducers: {
    deleteCardById (state, action: PayloadAction<string>) {
      state.cards = state.cards.filter((card) => card._id !== action.payload);
    },
    deleteBankById (state, action: PayloadAction<string>) {
      state.banks = state.banks.filter((bank) => bank._id !== action.payload);
    },
    deleteWalletById (state, action: PayloadAction<string>) {
      state.wallets = state.wallets.filter((wallet) => wallet._id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWallets.fulfilled, (state, action: PayloadAction<IWallet[]>) => {
      state.wallets = action.payload;
    });
    builder.addCase(getBanks.fulfilled, (state, action: PayloadAction<IBank[]>) => {
      state.banks = action.payload;
    });
    builder.addCase(getCards.fulfilled, (state, action: PayloadAction<ICard[]>) => {
      state.cards = action.payload;
    });
    builder.addCase(addCard.fulfilled, (state, action: PayloadAction<ICard>) => {
      state.cards.push(action.payload);
    });
    builder.addCase(addBank.fulfilled, (state, action: PayloadAction<IBank[]>) => {
      state.banks = action.payload;
    });
    builder.addCase(addWallet.fulfilled, (state, action: PayloadAction<IWallet[]>) => {
      state.wallets = action.payload;
    });
  }
});

export const { deleteCardById, deleteBankById, deleteWalletById } = withdrawalsMethodsSlice.actions;

export default withdrawalsMethodsSlice.reducer;
