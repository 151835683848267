import React, { type FC, useEffect, useState } from 'react'
import { Stack } from '@mui/material';
import { t } from 'i18next'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type PieChartDataInterface, type TabInterface, getPieChartData } from '../../../../redux/reducers/profile'
import EmptyTableIcon from '../../../../assets/images/emptyTable.svg';
import {
  getProfileSelectedTab,
  selectProfileIsPieChartErrorOccur,
  selectProfileIsPieChartLoading,
  selectProfilePieData
} from '../../../../redux/selectors/profile'
import { ProgressComponent } from '../../../../components';
import { DoughnutChart } from '../DoughnutChart/DoughnutChart';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import style from './CustomPieChart.module.css'
import { type IAccount } from '../../../../redux/reducers/accounts';

export const CustomPieChart: FC = () => {
  const dispatch = useAppDispatch()
  const [screenWidth] = useWindowSize();
  const [noDataValue, setNoDataValue] = useState<boolean>(false);
  const [chartColor, setChartColor] = useState<Record<string, string>>({
    'Loss: 0%': '#FF616A',
    'Profit: 0%': '#4E5B92'
  });

  const data = useAppSelector(selectProfilePieData)
  const selectedTab: IAccount | null = useAppSelector(getProfileSelectedTab)
  const isPieChartLoading: boolean = useAppSelector(selectProfileIsPieChartLoading)
  const isPieChartErrorOccur: boolean = useAppSelector(selectProfileIsPieChartErrorOccur)

  const isMobile = screenWidth <= 600;

  useEffect(() => {
    if (data === undefined || data === null) return;

    const updatedChartColor: Record<string, string> = {};

    for (let i = 0; i < data.length; i++) {
      if (i === 1) {
        const [key] = data[i];
        updatedChartColor[key as unknown as string] = '#4E5B92';
      }
      if (i === 2) {
        const [key] = data[i];
        updatedChartColor[key as unknown as string] = '#FF616A';
      }
    }

    const lossValue = data?.[1]?.[1];
    const profitValue = data?.[2]?.[1];

    const isLossValueZero = (typeof lossValue === 'number' && lossValue === 0) ||
      (typeof lossValue === 'string' && lossValue === '0');
    const isProfitValueZero = (typeof profitValue === 'number' && profitValue === 0) ||
        (typeof profitValue === 'string' && profitValue === '0');

    setNoDataValue(isLossValueZero && isProfitValueZero);

    setChartColor(updatedChartColor);
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (selectedTab) {
      dispatch(getPieChartData())
    }
  }, [selectedTab])

  if (noDataValue || isPieChartErrorOccur) {
    return (
      <div className={style.noDataWrapper}>
        <div className={style.emptyContainer}>
          <img src={EmptyTableIcon} alt="no_data" />
          <span>{t('labels.no_data')}</span>
          <span>{t('labels.nothing_found')}</span>
        </div>
      </div>
    )
  }

  if (data === null || isPieChartLoading) {
    return (
      <div className={style.loaderWrapper}>
        <ProgressComponent type='circular' />
      </div>
    )
  }

  const resultPieData = data.length === 3 ? data : [['Status', 'Amount'], ['Profit: 0%', 0], ['Loss: 0%', 0]];

  return (
    <div className={style.wrapper}>
      <Stack direction="column" spacing={0} alignItems="center" sx={{ width: '100%', minHeight: '174px' }}>
        <DoughnutChart data={resultPieData as PieChartDataInterface} colors={chartColor} isMobile={isMobile} />
      </Stack>
    </div>
  )
}
