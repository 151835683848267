import React, { type FC } from 'react'
import { CustomPieChart } from './CustomPieChart/CustomPieChart'
import { t } from 'i18next'
import style from './TradingChart.module.css'

export const TradingChart: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <div className={style.title}>
        {t('profile_page.labels.success_rate')}
        </div>
      </div>
      <div className={style.content}>
        <CustomPieChart />
      </div>
    </div>
  )
}
