import React, { type FC, type SyntheticEvent } from 'react';
import { type SelectChangeEvent } from '@mui/material/Select';
import { type OptionInterface } from '../../interfaces';
import { MenuItem, TextField, Autocomplete, Paper } from '@mui/material';

import { styled } from '@mui/material/styles';

interface ICountrySelect {
  options: OptionInterface[]
  value?: string | OptionInterface
  id?: string
  height?: number
  onChange?: (event: SelectChangeEvent<unknown>) => void
  onSearch?: (value: string | OptionInterface | null) => void
  disabled?: boolean
}

const CustomizedInput = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    position: 'relative',
    paddingLeft: '16px',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'unset',
    '&.Mui-focused': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '3px solid var(--CFD-theme-System-Primary)'
      },
      '&::after': {
        content: '""',
        display: 'none'
      }
    },
    '&:hover': {
      '&::after': {
        display: 'none'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '55%',
      right: '18px',
      transform: 'translateY(-50%) rotate(180deg)',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent var(--CFD-theme-System-OnSurfaceVariant) transparent',
      display: 'block'
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    width: '14px',
    height: '14px'
  },
  '& .MuiIconButton-root': {
    border: '2px solid var(--CFD-theme-System-OnSurfaceVariant)',
    borderRadius: '50%',
    height: '16px',
    width: '16px',
    right: '10px'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: 0,
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}));

const CustomizedMenuItem = styled(MenuItem)(() => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  }
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  borderRadius: '4px',
  padding: 0
}));

const handleSearch = (
  event: SyntheticEvent<Element, Event>,
  value: string | OptionInterface | null,
  reason: string,
  onSearch: (value: string | OptionInterface | null) => void
): void => {
  if (typeof onSearch === 'function') onSearch(value);
}

export const CountrySelect: FC<ICountrySelect> = ({
  options = [],
  value = '',
  id = '',
  onSearch = () => {},
  disabled = false
}) => {
  return (
    <Autocomplete
      id={id ?? 'country-select'}
      PaperComponent={CustomPaper}
      options={options}
      disabled={disabled}
      clearOnBlur
      value={value}
      onChange={(event, newValue, reason) => { handleSearch(event, newValue, reason, onSearch) }}
      freeSolo
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <CustomizedMenuItem
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
              alt=""
            />
            {option.label}
          </CustomizedMenuItem>
        );
      }}
      renderInput={(params) => (
        <CustomizedInput
          {...params}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              autoComplete: 'new-password'
            }
          }}
        />
      )}
    />
  );
}
