import React, { type FC } from 'react';

interface IWithdrawalChartIcon {
  cx: number
  cy: number
  iconColor: string
  bgcolor: string
}

export const WithdrawalChartIcon: FC<IWithdrawalChartIcon> = ({ cx, cy, bgcolor, iconColor }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" x={cx - 10} y={cy - 10}>
      <rect width="20" height="20" rx="10" fill={bgcolor} />
      <path d="M14 13V14H6V13H14Z" fill={iconColor} />
      <path d="M7.52502 10.7679L10.357 7.9359L9.29632 6.87524L12.1212 6.87878L12.1247 9.70367L11.0641 8.64301L8.23212 11.475L7.52502 10.7679Z" fill={iconColor} />
    </svg>
  )
}
