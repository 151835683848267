import React, {
  type HTMLAttributes,
  type FC,
  useState,
  useRef,
  useEffect
} from 'react';
import { CreditCardIcon } from '../../../../icons/CreditCardIcon';
import { VerificationFilePicker } from '../../../VerificationFilePicker/VerificationFilePicker';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { addVerificationRequest } from '../../../../../redux/reducers/kycVerification';
import { kycVerificationSelector } from '../../../../../redux/selectors/kycVerification';
import styles from './Step2SectionModalVerifications.module.css';
import { t } from 'i18next';

interface InterfaceStep2SectionModalVerifications {
  setFrontSideCardPicked: (el: boolean) => void
  setBackSideCardPicked: (el: boolean) => void
}

type TStep2SectionModalVerifications = InterfaceStep2SectionModalVerifications & HTMLAttributes<HTMLElement>

export const Step2SectionModalVerifications: FC<TStep2SectionModalVerifications> = ({ setBackSideCardPicked, setFrontSideCardPicked }) => {
  const dispatch = useAppDispatch()

  const [cardFrontSide, setCardFrontSide] = useState<File | null>(null);
  const [cardBackSide, setCardBackSide] = useState<File | null>(null);
  const frontSideCardRef = useRef<HTMLInputElement | null>(null);
  const backSideCardRef = useRef<HTMLInputElement | null>(null);

  const { creditCardFrontData: uploadedCreditCardFront, creditCardBackData: uploadedCreditCardBack } = useAppSelector(kycVerificationSelector);

  const formatAddedDate = (date?: string): string => {
    const dateToFormat = date !== undefined ? new Date(date) : new Date();
    return dateToFormat.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  const idCardFrontName = uploadedCreditCardFront !== null ? uploadedCreditCardFront.fileUrl : ''
  const idCardBackName = uploadedCreditCardBack !== null ? uploadedCreditCardBack.fileUrl : ''
  const idCardFrontDateAdded = uploadedCreditCardFront !== null ? formatAddedDate(uploadedCreditCardFront.updatedAt) : formatAddedDate()
  const idCardBackDateAdded = uploadedCreditCardBack !== null ? formatAddedDate(uploadedCreditCardBack.updatedAt) : formatAddedDate()

  const uploadFiles = (): void => {
    if ((cardFrontSide !== null) || (cardBackSide !== null)) {
      const formData = new FormData();

      if (cardFrontSide !== null) formData.append('creditCardFront', cardFrontSide, 'creditCardFrontFile');

      if (cardBackSide !== null) formData.append('creditCardBack', cardBackSide, 'creditCardBackFile');

      dispatch(addVerificationRequest(formData));
    }
  };

  useEffect(() => {
    uploadFiles()
  }, [cardFrontSide, cardBackSide])

  const handleDocsPicker = (inputElement: HTMLInputElement | null): void => {
    if (inputElement !== null) {
      inputElement.click();
    }
  }

  return (
    <section className={ styles.dialogContent }>
      <div className={ styles.dialogContentTopContainer }>
        <CreditCardIcon/>
        <h3 className={ styles.dialogContentTitle }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.title')}
        </h3>
      </div>
      <div className={ styles.dialogContentTextContainer }>
        <p className={ styles.dialogContentText }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.subtitle')}
        </p>
      </div>
      <div className={ styles.dialogContentBottomContainer }>
        {
          cardFrontSide === null && (uploadedCreditCardFront === null || uploadedCreditCardFront.verifiedStatus === 'declined')
            ? <VerificationFilePicker
                ref={ frontSideCardRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.upload_titles.front')}
                handleFileChange={ setCardFrontSide }
                handleDocsPicker={ handleDocsPicker }
                docs={ cardFrontSide }
                isDocsPicked={ setFrontSideCardPicked }
              />
            : <div className={styles.dialogUploadFilesWrapper}>
                <p className={styles.dialogUploadFilesTitle}>{t('labels.front_of_credit_card')}</p>
                <div className={ styles.dialogUploadFiles }>
                  <div className={ styles.dialogUploadFilesInfo }>
                    <p className={ styles.dialogUploadFilesInfoName }>{idCardFrontName}</p>
                    <p className={ styles.dialogUploadFilesInfoTime }>
                    {t('labels.added')}: {idCardFrontDateAdded}
                    </p>
                  </div>
                </div>
              </div>
        }
        {
          cardBackSide === null && (uploadedCreditCardBack === null || uploadedCreditCardBack.verifiedStatus === 'declined')
            ? <VerificationFilePicker
                ref={ backSideCardRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.upload_titles.back')}
                handleFileChange={ setCardBackSide }
                handleDocsPicker={ handleDocsPicker }
                docs={ cardBackSide }
                isDocsPicked={ setBackSideCardPicked }
              />
            : <div className={styles.dialogUploadFilesWrapper}>
                <p className={styles.dialogUploadFilesTitle}>{t('labels.back_of_credit_card')}</p>
                <div className={ styles.dialogUploadFiles }>
                  <div className={ styles.dialogUploadFilesInfo }>
                    <p className={ styles.dialogUploadFilesInfoName }>{idCardBackName}</p>
                    <p className={ styles.dialogUploadFilesInfoTime }>
                    {t('labels.added')}: {idCardBackDateAdded}
                    </p>
                  </div>
                </div>
              </div>
        }
        <p className={ styles.dialogContentBottomFileSizeText }>{t('labels.file_size')}: 10MB in JPG, JPEG, PNG</p>
      </div>
    </section>
  )
}
