import React, { type FC } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { type IFormattedChartData } from '../CurrencyTabs/CurrencyTabs';
import { getCurrencySymbol } from '../../../../helpers/util';
import { useTheme } from '../../../../components';
import { WithdrawalChartIcon } from '../../../../components/icons/WithdrawalChartIcon';
import { DepositChartIcon } from '../../../../components/icons/DepositChartIcon';
import { OrderChartIcon } from '../../../../components/icons/OrderChartIcon';
import { ExchangeChartIcon } from '../../../../components/icons/ExchangeChartIcon';

import style from './BalanceStatisticChart.module.css';

interface IBalanceStatisticChart {
  data: IFormattedChartData[]
  currencySymbol: string
  tableWidth: number
}

const CustomizedDot: FC<any> = (props: any) => {
  const { cx, cy, payload, bgcolor, iconColor } = props;

  const type = payload.type;

  if (!cx || !cy) return null;

  switch (type) {
    case 'Withdrawal':
      return (
        <WithdrawalChartIcon cx={cx} cy={cy} bgcolor={bgcolor} iconColor={iconColor} />
      );

    case 'Order':
      return (
        <OrderChartIcon cx={cx} cy={cy} bgcolor={bgcolor} iconColor={iconColor} />
      );

    case 'Exchange':
      return (
        <ExchangeChartIcon cx={cx} cy={cy} bgcolor={bgcolor} iconColor={iconColor} />
      );

    default:
      return (
        <DepositChartIcon cx={cx} cy={cy} bgcolor={bgcolor} iconColor={iconColor} />
      );
  }
};

const CustomTooltip = ({ active, payload }: { active: boolean, payload: any }): JSX.Element | null => {
  if (active && payload?.length) {
    const type = payload[0]?.payload?.type;
    const currency = payload[0]?.payload?.accountCurrency;
    const amount = payload[0]?.payload?.actionAmount;
    const timeFrame = payload[0]?.payload.tooltipTimeFrame
    return (
      <div className={ style.tooltip }>
        <div className={ style.tooltipBox }>
          <div className={ style.tooltipHeader }>{type}</div>
          <div className={ style.tooltipAmount }>{`${getCurrencySymbol(currency as string)} ${amount}`}</div>
        </div>
        <div className={ style.timeBlock }>
          {timeFrame}
        </div>
      </div>
    );
  }

  return null;
};

const yAxisFormatter = (value: number, currencySymbol: string): string => {
  return `${value}${getCurrencySymbol(currencySymbol)}`;
};

export const BalanceStatisticChart: FC<IBalanceStatisticChart> = ({ data, currencySymbol, tableWidth }) => {
  const { theme } = useTheme();

  const textColor = theme === 'light' ? '#BDBBD0' : '#343745';
  const strokeColor = theme === 'light' ? '#B7C4FF' : '#4E5B92';
  const strokeGridColor = theme === 'light' ? '#292F47' : '#D5D9EB';
  const iconColor = theme === 'light' ? '#181A21' : '#E3E1E9';

  return (
      <LineChart
        width={tableWidth ?? 600}
        height={400}
        data={data}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid
        vertical={true}
        strokeDasharray="none"
        stroke={strokeGridColor}
        strokeWidth={0.1}
        horizontal={false}
        />
        <XAxis
          dataKey="createdAt"
          tick={{
            stroke: `${textColor}`,
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '300',
            fontFamily: 'Arial'
          }}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          tick={{
            stroke: `${textColor}`,
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: '300',
            fontFamily: 'Arial',
            dx: -8
          }}
          tickFormatter={(value) => yAxisFormatter(value as number, currencySymbol)}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          content={
            <CustomTooltip active={false} payload={undefined} />
          }
        />
        <Line
          type="monotone"
          dataKey="finalAmount"
          stroke={strokeColor}
          dot={<CustomizedDot bgcolor={strokeColor} iconColor={iconColor} />}
        />
      </LineChart>
  );
}
