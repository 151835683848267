import React, { type FC, useState, useEffect, type FocusEvent } from 'react';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { createExternalTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { toFixed } from '../../../../helpers/util';
import {
  AssetAmountInputComponent,
  ButtonComponent
} from '../../../../components';
import { SuccessWithdrawalSection } from '../SuccessWithdrawalSection/SuccessWithdrawalSection';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { useWithdrawalDispatchTransaction } from '../../../../hooks/useWithdrawalDispatchTransaction';
import { AddInteracFormComponent } from '../AddInteracFormComponent/AddInteracFormComponent';

import style from './InteracWithdrawalSection.module.css';

export interface IInteracData {
  email: string
  bankName: string
  customerName: string
}

interface InterfaceInteracWithdrawSection {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  selectedAccount: IAccount | null
}

interface ICreateWithdrawalInteracData extends IInteracData {
  sendToAddress: string
  userId: string
  currencyId: string
  userAccountId: string
  amount: number
  amountFromAccount: string | number
  type: 'Interac'
}

export const InteracWithdrawSection: FC<InterfaceInteracWithdrawSection> = ({ setOpen, setStep, step, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [interacData, setInteracData] = useState<IInteracData>({ email: '', bankName: '', customerName: '' });
  const [amount, setAmount] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const { executeTransaction } = useWithdrawalDispatchTransaction(setIsBtnLoading);

  const { _id: userId } = useAppSelector((state) => state.user);

  const validInteracData = (
    interacData.email.length > 0 &&
      interacData.bankName.length > 0 &&
      interacData.customerName.length > 0 &&
      amount.length > 0 &&
      Number(amount) > 0 &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(interacData.email)
  );

  const handleWithdrawlClick = async (): Promise<void> => {
    if (userId === undefined || selectedAccount === null || !validInteracData) return;

    setIsBtnLoading(true);

    const createWithdrawalInteracData = (): ICreateWithdrawalInteracData => {
      return {
        userId,
        sendToAddress: `"${interacData.bankName}", "${interacData.customerName}"`,
        currencyId: selectedAccount?.cfdAccountCurrency?.currencyId,
        userAccountId: selectedAccount._id,
        email: interacData.email,
        bankName: interacData.bankName,
        customerName: interacData.customerName,
        amount: Number(amount),
        amountFromAccount: amount,
        type: 'Interac'
      };
    };

    const withdrawalData = createWithdrawalInteracData();

    const transactionSuccess = await executeTransaction(createExternalTransaction, withdrawalData);
    if (!transactionSuccess) return;

    dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id }));
    setStep(2);
  };

  const handleCloseWithdrawal = (): void => {
    setOpen(false);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    // Handle focus event if needed
  };

  return (
      <>
        { (step === 1)
          ? <>
              <div className={ style.wrapper }>
                <AddInteracFormComponent
                    interacData={ interacData }
                    setInteracData={ setInteracData }
                    onFocus={ handleFocus }
                />
                <div className={ style.cardWrapper }>
                  <p className={ style.cardWrapperText }>{t('labels.amount')}</p>
                  <AssetAmountInputComponent
                      value={ amount }
                      setValue={ setAmount }
                      id='amount'
                      currency={selectedAccount?.cfdAccountCurrency.symbol ?? ''}
                      maxBtn={ false }
                      placeholder='0.00'
                      showCurrency={false}
                  />
                </div>
              </div>
              <footer className={ style.footer }>
                <div className={ style.btnContainer }>
                  <ButtonComponent onClick={() => { handleWithdrawlClick(); } } disabled={ !validInteracData } loading={isBtnLoading}>
                  <span className={ style.btnText }>
                    {t('withdrawal_modal.add_bank_form_modal.footer.action_button_text')}
                  </span>
                  </ButtonComponent>
                </div>
              </footer>
            </>
          : <SuccessWithdrawalSection
                title={t('withdrawal_modal.success_title')}
                amount={`${toFixed(Number(amount), 2)}`}
                currency={selectedAccount?.cfdAccountCurrency.symbol ?? ''}
                bankName={interacData.bankName}
                iban={interacData.email}
                onClick={ handleCloseWithdrawal }
                sectionName='Interac'
            />
        }
      </>
  )
}
