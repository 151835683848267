import React, { type FC, useState, useEffect } from 'react';
import style from './Step1SectionModalAutoTrader.module.css';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { SwitchComponent } from '../../../SwitchComponent/SwitchComponent';
import { RadioButtonComponent } from '../../../RadioButtonComponent/RadioButtonComponent';
import { setAutotrader } from '../../../../redux/reducers/autotrader';
import { t } from 'i18next';
import { RadioGroup, FormControlLabel, FormControl, styled } from '@mui/material';

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '16px',
  overflow: 'auto',
  maxWidth: '38vw',
  justifyContent: 'space-between',

  '@media (max-width: 1239px)': {
    maxWidth: '100vw'
  }
}))

type RiskLevelKey = 'low' | 'medium' | 'high';

const getRiskLevels = (): Record<RiskLevelKey, { label: string, days: string }> => ({
  low: { label: t('trading_page.labels.low'), days: '1-3' },
  medium: { label: t('trading_page.labels.medium'), days: '3-4' },
  high: { label: t('trading_page.labels.high'), days: '5+' }
});

export const Step1SectionModalAutoTrader: FC = () => {
  const dispatch = useAppDispatch();
  const [widthScreen] = useWindowSize();
  const [riskLevel, setRiskLevel] = useState('low');
  const [riskLevels, setRiskLevels] = useState(getRiskLevels());
  const autotrader = useAppSelector((state) => state.autotrader);

  const isMobile = (widthScreen <= 905);
  useEffect(() => {
    setRiskLevels(getRiskLevels());
  }, [t]);

  useEffect(() => {
    setRiskLevel(autotrader.riskLevel === 'disabled' ? 'low' : autotrader.riskLevel);
  }, [autotrader.riskLevel]);

  const handleChangeAutotrader = (checked: boolean): void => {
    dispatch(setAutotrader({ ...autotrader, riskLevel: checked ? riskLevel : 'disabled' }))
  }

  const handleChangeRiskLevel = (riskLevel: string): void => {
    dispatch(setAutotrader({ ...autotrader, riskLevel }))
  }

  return (
    <section className={style.sectionWrapper}>
      <div className={style.sectionAdaptivFirst}>
        {isMobile && (
          <p className={style.dialogAutoTraider}>
            {t('trading_page.labels.autoTrader')}
          </p>
        )}
        <div className={style.toggle}>
          <p>
            {t('trading_page.labels.toggleOn')} {t('trading_page.labels.autoTrader')}
          </p>
          <SwitchComponent
            checked={autotrader.riskLevel !== 'disabled'}
            onChange={ ({ target: { checked } }) => { handleChangeAutotrader(checked); }}
          />
        </div>
        <div className={style.riskLevel}>
          <p className={style.riskLevel_p}>
            {t('trading_page.labels.riskLevel')}
          </p>
          <div className={style.levels}>

            <StyledFormControl disabled={autotrader.riskLevel === 'disabled'}>
              <StyledRadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={riskLevel}
                onChange={(e) => { handleChangeRiskLevel(e.target.value) }}
              >
                {Object.keys(riskLevels).map((key) => (
                  <FormControlLabel
                    sx={{
                      border: `1px solid ${autotrader.riskLevel === key ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OutlineVariant)'}`,
                      borderRadius: '6px',
                      height: '48px',
                      marginLeft: '0',
                      marginRight: '0',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
                      }
                    }}
                    key={key}
                    value={key}
                    control={
                      <RadioButtonComponent
                        checked={riskLevel === key}
                        onChange={() => { dispatch(setAutotrader({ ...autotrader, riskLevel: key })) }}
                        value={key}
                      />
                    }
                    label={<div className={style.assetsCheckContainer}>
                      <p className={style.assetsCheckContainerTitle}>{riskLevels[key as RiskLevelKey].label}</p>
                    </div>}
                  />
                ))}
              </StyledRadioGroup>
            </StyledFormControl>

          </div>
          <p>{t('trading_page.labels.tradesDay')}: {riskLevels?.[autotrader.riskLevel as RiskLevelKey]?.days ?? 0}</p>
        </div>
      </div>
      <div className={style.sectionAverage}></div>
      <div className={style.sectionAdaptivSecond}>
        {isMobile && <div className={style.spanHr}></div>}
        <div className={style.blockTextRisk}>
          <div className={style.textRisk}>
            <p>{t('trading_page.labels.textAutoTrader')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
